import { createContext, useEffect, useState } from "react";
import { API_URL, VIDEO_BUCKET } from "../config";
import { getVideoUploader } from "@arena-active/client-lib";

// the model as singleton
const videoUploader = getVideoUploader(API_URL, VIDEO_BUCKET);

type VideoUploadsProviderProps = {
  children: React.ReactNode;
};

export type Upload = {
  name: string;
  state: string;
  uploadProgress: number;
  downloadUrl: string;
};

export const VideoUploadsContext = createContext<
  | {
      uploads: Upload[];
      uploadDialogVisible: boolean;
      setUploadDialogVisible: (visible: boolean) => void;
      addUpload: (file: File) => void;
    }
  | undefined
>(undefined);

export const VideoUploadsProvider: React.FC<VideoUploadsProviderProps> = ({
  children,
}) => {
  const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
  const [uploads, setUploads] = useState<Upload[]>([]);

  function addUpload(file: File): void {
    videoUploader.upload(file);
  }

  useEffect(() => {
    function handleStateChange() {
      // newest uploads first
      const jobs = videoUploader.jobs.slice().sort((a, b) => {
        return b.createdAt.getTime() - a.createdAt.getTime();
      });
      setUploads(
        jobs.map((job) => ({
          name: job.file.name,
          state: job.state,
          uploadProgress: job.uploadProgress,
          downloadUrl: job.downloadUrl,
        })),
      );
    }
    videoUploader.addEventListener("stateChange", handleStateChange);
    return () => {
      videoUploader.removeEventListener("stateChange", handleStateChange);
    };
  }, []);

  return (
    <VideoUploadsContext.Provider
      value={{
        uploads,
        uploadDialogVisible,
        setUploadDialogVisible,
        addUpload,
      }}
    >
      {children}
    </VideoUploadsContext.Provider>
  );
};
