import { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useParams } from "react-router-dom";
import { standaloneTRPCClient } from "@arena-active/trpc-client";
import { API_URL } from "../config";

type BreadcrumbItem = {
  icon?: string;
  label?: string;
  url: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Breadcrumbs({ styles }: any) {
  const params = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  // Use useRef to hold the stable reference to the client
  const trpcClientRef = useRef(standaloneTRPCClient(API_URL));

  function truncateText(text: string, maxLength: number = 32): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  const home: BreadcrumbItem = {
    icon: "pi pi-home",
    url: "/",
  };
  useEffect(() => {
    async function fetchBreadcrumbs() {
      const breadcrumbItems: BreadcrumbItem[] = [];
      const trpcClient = trpcClientRef.current;

      if (params.courseId) {
        const courseId = Number(params.courseId);
        const course = await trpcClient.course.get.query({ id: courseId });
        breadcrumbItems.push({
          label: truncateText(course?.title ?? ""),
          url: `/course/${courseId}`,
        });
      }

      if (params.lessonId) {
        const lessonId = Number(params.lessonId);
        const lesson = await trpcClient.lesson.get.query({ id: lessonId });
        breadcrumbItems.push({
          label: truncateText(lesson.lesson?.title ?? ""),
          url: `/course/${params.courseId}/lesson/${lessonId}`,
        });
      }

      if (params.objectiveId) {
        const objectiveId = Number(params.objectiveId);
        const objective = await trpcClient.objective.get.query({
          id: objectiveId,
        });
        breadcrumbItems.push({
          label: truncateText(objective?.summary ?? ""),
          url: `/course/${params.courseId}/lesson/${params.lessonId}/objective/${objectiveId}`,
        });
      }

      if (params.promptId) {
        const promptId = Number(params.promptId);
        const prompt = await trpcClient.prompt.get.query({ id: promptId });
        breadcrumbItems.push({
          label: truncateText(prompt.data?.prompt ?? ""),
          url: `/course/${params.courseId}/lesson/${params.lessonId}/objective/${params.objectiveId}/prompt/${promptId}`,
        });
      }

      setBreadcrumbs(breadcrumbItems);
    }

    fetchBreadcrumbs();
  }, [params]);

  return (
    <div className={styles.breadcrumbContainer}>
      <BreadCrumb model={breadcrumbs} home={home} />
    </div>
  );
}

export default Breadcrumbs;
