import { trpc } from "@arena-active/trpc-client";
import { useState, useEffect, useRef } from "react";
import { ProgressBar } from "primereact/progressbar";
import { useParams } from "react-router-dom";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { ActiveSessionExercises } from "./ActiveSessionExercises";

import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { SelectLessonCourse } from "./SelectLessonCourse";
import { ToggleButton } from "primereact/togglebutton";

function ActiveLessonDetail() {
  const toast = useRef<Toast>(null);
  const [error, setError] = useState<Error | null>(null);
  const utils = trpc.useUtils();
  const { activeLessonId } = useParams();
  const numberId = activeLessonId ? parseInt(activeLessonId) : -1;
  if (numberId == -1) setError(new Error("no id"));
  const { data: activeLesson, isLoading } = trpc.activeLesson.get.useQuery({
    id: numberId,
  });
  const updateActiveLessonMutation = trpc.activeLesson.update.useMutation();
  const addActiveSessionMutation =
    trpc.activeLesson.addActiveSession.useMutation();
  const updateActiveSessionMutation = trpc.activeSession.update.useMutation();
  const removeSessionLessonMutation =
    trpc.activeSession.removeLesson.useMutation();

  const setLessonMutation = trpc.activeSession.setLesson.useMutation();
  const lessonListQuery = trpc.lesson.list.useQuery({});
  const { data: publishedLessonsData, isLoading: publishedLessonsDataLoading } =
    trpc.publishedActiveLesson.find.useQuery({ activeLessonId: numberId });

  const { mutateAsync: publishActiveLessonAsync, isError: publishLessonError } =
    trpc.publishedActiveLesson.publish.useMutation();

  const publishLesson = async (lessonId: number) => {
    if (lessonId) {
      try {
        await publishActiveLessonAsync({ activeLessonId: lessonId });
        utils.publishedActiveLesson.find.invalidate();
        toast.current?.show({
          severity: "success",
          summary: "Published",
          detail: `published active lesson ${lessonId}`,
          life: 3000,
        });
        if (publishLessonError) {
          toastError(`problem deleting session`);
        }
      } catch (error) {
        toastError(`problem deleting session`, error);
      }
    }
  };

  const { mutateAsync: deleteSessionAsync, isError: deleteSessionError } =
    trpc.activeSession.delete.useMutation();

  const removeSession = async (sessionId: number) => {
    if (sessionId) {
      try {
        await deleteSessionAsync({ id: sessionId });
        utils.activeLesson.invalidate();
        if (deleteSessionError) {
          toastError(`problem deleting session ${deletePublishedLessonError}`);
        }
      } catch (error) {
        console.error(error);
        toastError(`problem deleting session`, error);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function toastError(message: string, error?: any) {
    console.error(message, error);
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  }
  const {
    mutateAsync: deletePublishedLessonAsync,
    isError: deletePublishedLessonError,
  } = trpc.publishedActiveLesson.delete.useMutation();

  const removePublishedLesson = async (publishedActiveLessonID: number) => {
    if (publishedActiveLessonID) {
      try {
        await deletePublishedLessonAsync({ id: publishedActiveLessonID });
        toast.current &&
          toast.current.show({
            severity: "info",
            summary: "Deleted",
            detail: `deleted published lesson ${publishedActiveLessonID}`,
            life: 3000,
          });
        utils.publishedActiveLesson.find.invalidate();
        if (deletePublishedLessonError) {
          toastError(
            `problem deleting published lesson ${publishedActiveLessonID}`,
          );
          console.error(
            `problem deleting published active lesson ${deletePublishedLessonError}`,
          );
        }
      } catch (error) {
        console.error(error);
        toastError(
          `problem deleting published lesson ${publishedActiveLessonID}`,
        );
      }
    }
  };

  const confirmRemovePublishedActiveLesson = (id: number) => {
    confirmDialog({
      message:
        "Deleting this Published Session will delete all responses and user activity associated with it, are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => removePublishedLesson(id),
      reject: () => {},
    });
  };

  const [dropdownSelections, setDropdownSelections] = useState<{
    [sessionId: number]: number;
  }>({});

  const onDropdownChange = async (sessionId: number, lessonId: number) => {
    setDropdownSelections((prevSelections) => ({
      ...prevSelections,
      [sessionId]: lessonId,
    }));
    try {
      await setLessonMutation.mutateAsync({
        lessonId,
        activeSessionId: sessionId,
      });
      utils.activeSession.get.invalidate();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (activeLesson && activeLesson.activeSessions) {
      const initialSelections = activeLesson.activeSessions.reduce(
        (acc, session) => ({
          ...acc,
          [session.id]: session.lessonId || null, // Assuming session.lessonId is the correct field
        }),
        {},
      );
      setDropdownSelections(initialSelections);
    }
  }, [activeLesson]);

  const addActiveSession = async () => {
    await addActiveSessionMutation.mutateAsync({
      activeLessonId: activeLesson?.id ?? -1,
      activeSession: { title: "untitled" },
    });
    utils.activeLesson.get.invalidate();
  };

  const renameActiveLesson = async (id: number, newTitle: string) => {
    await updateActiveLessonMutation.mutateAsync({
      id,
      title: newTitle,
    });
    utils.activeLesson.get.invalidate();
  };

  const setActiveLessonAutoEnroll = async (id: number, state: boolean) => {
    await updateActiveLessonMutation.mutateAsync({
      id,
      autoEnrol: state,
    });
    utils.activeLesson.get.invalidate();
  };

  const renameActiveSession = async (id: number, newTitle: string) => {
    await updateActiveSessionMutation.mutateAsync({
      id,
      title: newTitle,
    });
    utils.activeLesson.get.invalidate();
  };

  const removeLesson = async (activeSessionId: number) => {
    if (activeSessionId) {
      try {
        await removeSessionLessonMutation.mutateAsync({ activeSessionId });
        utils.activeSession.get.invalidate();
        setDropdownSelections((prevSelections) => {
          const newSelections = { ...prevSelections };
          delete newSelections[activeSessionId];
          return newSelections;
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const editableHeaderTemplate = (
    id: number,
    title: string,
    onEdit: (id: number, newTitle: string) => void,
    additionalContent?: JSX.Element,
  ) => {
    return (
      <div className="p-panel-header">
        <Inplace closable>
          <InplaceDisplay>{title || "Click to Edit"}</InplaceDisplay>
          <InplaceContent>
            <span className="mr-2 text-2xl">Title:</span>
            <InputText
              value={title}
              onChange={(e) => onEdit(id, e.target.value)}
              autoFocus
            />
          </InplaceContent>
        </Inplace>
        {additionalContent}
      </div>
    );
  };

  if (isLoading)
    return (
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      {activeLesson && (
        <SelectLessonCourse
          id={activeLesson.id}
          contentType="activeLesson"
          currentCourseId={activeLesson.courseId ?? undefined}
        />
      )}
      <Panel
        headerTemplate={() => (
          <>
            {editableHeaderTemplate(
              activeLesson?.id ?? -1,
              activeLesson?.title ?? "",
              renameActiveLesson,
              <ToggleButton
                checked={activeLesson?.autoEnrol ?? false}
                onLabel="AutoEnroll Enabled"
                offLabel="AutoEnroll Disabled"
                onChange={(e) => {
                  setActiveLessonAutoEnroll(
                    activeLesson?.id ?? -1,
                    e.target.value,
                  );
                }}
              />,
            )}
          </>
        )}
      >
        <Card title="Sessions">
          {activeLesson?.activeSessions?.map((session) => {
            return (
              <Panel
                key={session.id}
                className="p-4"
                headerTemplate={() =>
                  editableHeaderTemplate(
                    session.id,
                    session.title,
                    renameActiveSession,
                  )
                }
              >
                <span className="mr-2">Lesson:</span>
                <Dropdown
                  value={dropdownSelections[session.id]}
                  options={lessonListQuery.data?.lessons.map((lesson) => ({
                    label: lesson.title,
                    value: lesson.id,
                  }))}
                  onChange={(e) => onDropdownChange(session.id, e.value)}
                />
                <Button
                  onClick={() => removeLesson(session.id)}
                  icon="pi pi-times"
                  rounded
                  text
                  severity="warning"
                  aria-label="remove exercise"
                />
                <ActiveSessionExercises
                  sessionId={session.id}
                  lessonId={dropdownSelections[session.id]}
                />
                <Button
                  className="mt-4"
                  label="Delete This Session"
                  severity="danger"
                  text
                  onClick={() => removeSession(session.id)}
                ></Button>
              </Panel>
            );
          })}
          <Button label="Add Session" onClick={addActiveSession}></Button>
        </Card>
        <Card title="Published Instances" className="mt-4">
          <p className="m-0">
            Copies of the Active Lesson that have been deployed for use.
          </p>
          {publishedLessonsDataLoading ? (
            <div>loading...</div>
          ) : (
            <DataTable value={publishedLessonsData}>
              <Column field="id" header="ID"></Column>
              <Column field="createdAt" header="Created"></Column>
              <Column
                field=""
                header=""
                body={(rowData) => (
                  <>
                    <Button
                      label="delete"
                      text
                      severity="warning"
                      onClick={() =>
                        confirmRemovePublishedActiveLesson(rowData.id)
                      }
                    />
                  </>
                )}
              ></Column>
            </DataTable>
          )}

          <Button
            label="Publish New Instance"
            severity="success"
            className="mt-4"
            onClick={() => publishLesson(activeLesson?.id ?? -1)}
          ></Button>
        </Card>
      </Panel>
    </div>
  );
}

export default ActiveLessonDetail;
