import { standaloneTRPCClient } from "@arena-active/trpc-client";
import { IEncodingService } from "@arena-active/encoding";
import {
  FinishEncodingInput,
  FinishEncodingOutput,
  GetEncodingStatusInput,
  GetEncodingStatusOutput,
  StartEncodingInput,
  StartEncodingOutput,
} from "@arena-active/encoding";

type ServerOnlyServiceMethods = Pick<
  IEncodingService,
  "executeSimpleTranscode"
>;
export type IClientEncodingService = Omit<
  IEncodingService,
  keyof ServerOnlyServiceMethods
>;

export function makeEncodingServiceClient(
  trpcClientUrl: string,
): IClientEncodingService {
  const trpcClient = standaloneTRPCClient(trpcClientUrl);

  const startEncoding = async (
    input: StartEncodingInput,
  ): Promise<StartEncodingOutput> => {
    try {
      const output = await trpcClient.encoding.startEncoding.query(input);
      return output;
    } catch (error) {
      console.error("Failed to start encoding", error);
      throw new Error("Failed to start encoding");
    }
  };

  const getEncodingStatus = async (
    input: GetEncodingStatusInput,
  ): Promise<GetEncodingStatusOutput> => {
    try {
      const output = await trpcClient.encoding.getEncodingStatus.query(input);
      return output;
    } catch (error) {
      console.error("Failed to get encoding status", error);
      throw new Error("Failed to get encoding status");
    }
  };

  const finishEncoding = async (
    input: FinishEncodingInput,
  ): Promise<FinishEncodingOutput> => {
    try {
      const output = await trpcClient.encoding.finishEncoding.query(input);
      return output;
    } catch (error) {
      console.error("Failed to finish encoding", error);
      throw new Error("Failed to finish encoding");
    }
  };

  return {
    startEncoding,
    getEncodingStatus,
    finishEncoding,
  };
}
