import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { useEffect, useState } from "react";
import LessonList from "./LessonList";
import ActiveLessonList from "./ActiveLessonList";
import { trpc } from "@arena-active/trpc-client";
import { useParams } from "react-router-dom";

export const CourseDetail = () => {
  const [error, setError] = useState<Error | null>(null);

  const { courseId } = useParams();
  const _courseId = courseId ? parseInt(courseId) : -1;

  if (_courseId == -1) setError(new Error("no id"));
  const { data: course } = trpc.course.get.useQuery({ id: _courseId });

  const getInitialTabIndex = () => {
    const savedIndex = localStorage.getItem("courseDetailActiveTabIndex");
    if (savedIndex !== null) {
      return parseInt(savedIndex);
    }
    return 0; // Default to 0 if there is no saved index
  };

  const [activeTabIndex, setActiveTabIndex] = useState(getInitialTabIndex);

  useEffect(() => {
    localStorage.setItem(
      "courseDetailActiveTabIndex",
      activeTabIndex.toString(),
    );
  }, [activeTabIndex]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <TabView
      activeIndex={activeTabIndex}
      onTabChange={(e) => setActiveTabIndex(e.index)}
    >
      <TabPanel header="Active Lessons">
        <ActiveLessonList courseId={course?.id} />
      </TabPanel>
      <TabPanel header="Lesson Parts">
        <LessonList courseId={course?.id} />
      </TabPanel>
    </TabView>
  );
};

export default CourseDetail;
