import { useState, ChangeEvent, useRef, useEffect } from "react";
import styles from "./PlainTextEditor.module.css"; // Adjust the import path as needed

interface PlainTextEditorProps {
  initialText: string;
  onTextChange: (text: string) => void;
  isEditingState?: boolean;
  style?: React.CSSProperties;
}

function PlainTextEditor({
  initialText,
  onTextChange,
  isEditingState = false,
}: PlainTextEditorProps) {
  const [isEditing, setIsEditing] = useState(isEditingState);
  const [content, setContent] = useState(initialText);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditingState) {
      setIsEditing(true);
    }

    if (isEditing && textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto before setting to scrollHeight
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content, isEditing, isEditingState]);

  const handleDivClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
    onTextChange(event.target.value);
  };

  const handleTextareaBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className={styles.editorContainer}>
      {isEditing ? (
        <textarea
          rows={10}
          ref={textareaRef}
          className={styles.editableTextarea}
          value={content}
          onChange={handleTextareaChange}
          onBlur={handleTextareaBlur}
          style={{ overflow: "hidden" }} // Hide scrollbar
        />
      ) : (
        <div className={styles.editableDiv} onClick={handleDivClick}>
          {content || (
            <span className={styles.placeholderText}>Click to edit...</span>
          )}
        </div>
      )}
    </div>
  );
}

export default PlainTextEditor;
