import { useContext } from "react";
import { VideoUploadsContext } from "./VideoUploaderContext";

export function useVideoUploader() {
  const context = useContext(VideoUploadsContext);
  if (!context) {
    throw new Error(
      "useVideoUploader must be used within a VideoUploadsProvider",
    );
  }
  return context;
}
