import React, { useRef, useEffect } from "react";
import {
  MessageItem,
  PendingMessage,
  PendingMessageItem,
  ReadableMessage,
  isPendingMessage,
} from "./MessageItem";

interface MessageListProps {
  messages: (PendingMessage | ReadableMessage)[];
}

export const MessageList: React.FC<MessageListProps> = ({ messages }) => {
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div
      style={{
        flexGrow: 1,
        overflowY: "auto",
        padding: "10px",
      }}
    >
      {(messages || []).map((msg, index) => {
        if (isPendingMessage(msg)) {
          return <PendingMessageItem key={index} {...msg} />;
        } else {
          return (
            <MessageItem
              key={index}
              message={msg.content}
              isUser={msg.type === "HUMAN"}
            />
          );
        }
      })}
      <div ref={endOfMessagesRef} />
    </div>
  );
};
