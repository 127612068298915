// load the prompt
// input for response
// analyze response
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { SplitButton } from "primereact/splitbutton";
import { trpc } from "@arena-active/trpc-client";
import { Toast } from "primereact/toast";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Dialog } from "primereact/dialog";
import { Player, VideoPlayer } from "@arena-active/video-player";
import type { inferProcedureOutput } from "@trpc/server";
import type { AppRouter } from "@arena-active/api";

function PromptDetail() {
  const toast = useRef<Toast>(null);
  const utils = trpc.useUtils();
  const [error, setError] = useState<Error | null>(null);
  const { promptId } = useParams();
  const numberId = promptId ? parseInt(promptId) : -1;
  if (numberId == -1) setError(new Error("no id"));

  const updatePromptMutation = trpc.prompt.updatePrompt.useMutation();

  const [response, setResponse] = useState<string | null>(null);
  const [feedbackVideoUrl, setFeedbackVideoUrl] =
    useState<`${string}@${number}-${number}`>();
  const [feedback, setFeedback] = useState<
    Array<{
      prompt: string;
      response: string;
      segments: inferProcedureOutput<
        AppRouter["transcript"]["querySegmentTimings"]
      >;
    }>
  >([]);
  const [generatingFeedback, setGeneratingFeedback] = useState(false);
  const [feedbackGenerationThreshold, setFeedbackGenerationThreshold] =
    useState(20);
  const { data: promptData, isLoading } = trpc.prompt.get.useQuery({
    id: numberId,
  });
  const {
    mutateAsync: runGenerateResponse,
    data: generatedResponse,
    isLoading: generatingResponse,
  } = trpc.prompt.generateResponse.useMutation();
  const {
    mutateAsync: runResponseAnalysis,
    data: responseAnalysis,
    isLoading: analyzingResponse,
  } = trpc.prompt.analyzeResponse.useMutation();

  const prompt = promptData?.data;

  const [updatedPromptText, setUpdatedPromptText] = useState<string>("");

  const updatePrompt = async () => {
    if (!prompt) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "No prompt",
        life: 2000,
      });
      return;
    }
    if (prompt.prompt !== updatedPromptText) {
      await updatePromptMutation.mutateAsync({
        id: prompt.id,
        prompt: updatedPromptText,
      });
      toast.current?.show({
        severity: "success",
        summary: "Saved",
        detail: "Prompt updated",
        life: 2000,
      });
      utils.prompt.get.invalidate();
    }
  };

  useEffect(() => {
    setResponse(generatedResponse ?? null);
  }, [generatedResponse]);

  const analyzeResponse = async () => {
    try {
      await runResponseAnalysis({ id: numberId, response: response ?? "" });
    } catch (err) {
      setError(new Error("error analyzing response"));
    }

    setFeedback([]);
  };

  const generateFeedback = async () => {
    if (!responseAnalysis) {
      throw new Error(`Nothing to generate feedback queries for`);
    }

    const data = await utils.transcript.findFeedbackQueries.fetch({
      feedback: responseAnalysis.rationale,
    });

    const feedback = await Promise.all(
      data.questions.map(async (prompt) => {
        const response = await utils.transcript.query.fetch({
          query: prompt,
          threshold: feedbackGenerationThreshold / 100,
        });
        const segments = await utils.transcript.querySegmentTimings.fetch({
          query: prompt,
          threshold: feedbackGenerationThreshold / 100,
        });

        return {
          prompt,
          response,
          segments,
        };
      }),
    );

    setFeedback(feedback);
  };

  const generationRating = [
    {
      label: "Excellent",
      command: () => {
        generateResponse("Excellent");
      },
    },
    {
      label: "Good",
      command: () => {
        generateResponse("Good");
      },
    },
    {
      label: "Satisfactory",
      command: () => {
        generateResponse("Satisfactory");
      },
    },
    {
      label: "Needs Work",
      command: () => {
        generateResponse("Needs Work");
      },
    },
  ];

  async function generateResponse(desiredRating = "good") {
    try {
      await runGenerateResponse({ id: numberId, desiredRating });
    } catch (err) {
      setResponse("error generating response");
    }
  }

  if (isLoading)
    return (
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Toast ref={toast} />
      <Card>
        <Inplace closable>
          <InplaceDisplay>{prompt?.prompt || "Click to Edit"}</InplaceDisplay>
          <InplaceContent>
            <InputTextarea
              autoResize
              cols={80}
              value={updatedPromptText}
              onChange={(e) => {
                setUpdatedPromptText(e.target.value);
              }}
              onBlur={() => updatePrompt()}
              autoFocus
            />
          </InplaceContent>
        </Inplace>
      </Card>
      <Card>
        <span className="p-float-label">
          <InputTextarea
            id="response"
            value={response || "undefined"}
            onChange={(e) => setResponse(e.target.value)}
            rows={5}
            cols={80}
          />
          <label htmlFor="response">Enter Response</label>
        </span>
        <Button
          text
          label={analyzingResponse ? "Analysing" : "Analyze Response"}
          onClick={analyzeResponse}
          loading={analyzingResponse}
        ></Button>
        <SplitButton
          label={generatingResponse ? "Generating" : "Generate Response"}
          icon="pi pi-plus"
          onClick={() => generateResponse()}
          model={generationRating}
          severity="secondary"
          loading={generatingResponse}
          outlined
        />
      </Card>
      {analyzingResponse && (
        <Card>
          <Panel header="calculating rating...">
            <Skeleton height="4rem" style={{ margin: "1rem" }}></Skeleton>
          </Panel>
        </Card>
      )}
      {responseAnalysis && (
        <Card>
          <Panel header={responseAnalysis.rating}>
            <p className="m-0">{responseAnalysis.rationale}</p>
          </Panel>
          <Divider />
          <Fieldset legend="Feedback prompts">
            <p>
              Feedback prompts are used to provide additional context to the
              student after a response has been graded.
            </p>

            {responseAnalysis &&
              (responseAnalysis.rating === "excellent" ||
                responseAnalysis.rating === "good") && (
                <Message
                  style={{ display: "block", marginTop: "1rem" }}
                  severity="warn"
                  text="Generated feedback prompts may be weak (or un-answerable) for 'Excellent' or 'Good' ratings."
                />
              )}
            <div>
              <label
                style={{
                  display: "block",
                  marginBottom: "-16px",
                }}
                htmlFor="generation-threshold"
              >
                Threshold
              </label>
              <InputText
                id="generation-threshold"
                style={{ marginTop: "1rem" }}
                value={`${feedbackGenerationThreshold}`}
                min={0}
                max={100}
                type="number"
                onChange={(e) =>
                  setFeedbackGenerationThreshold(e.target.valueAsNumber)
                }
              />

              <Slider
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                value={feedbackGenerationThreshold}
                onChange={(e) =>
                  setFeedbackGenerationThreshold(e.value as number)
                }
              />
              <small
                style={{
                  display: "block",
                }}
                id="username-help"
              >
                Threshold adjusts the accuracy of results. A lower threshold is
                more likely to give a result, but it may be lower quality.
              </small>
            </div>
            <Button
              text
              label={
                generatingFeedback
                  ? "Generating"
                  : "Generate feedback prompts (will appear below)"
              }
              onClick={() => {
                setGeneratingFeedback(true);

                generateFeedback().then(
                  () => setGeneratingFeedback(false),
                  () => setGeneratingFeedback(false),
                );
              }}
              loading={generatingFeedback}
            ></Button>
          </Fieldset>
          <Dialog
            style={{ width: "50vw", height: "auto" }}
            header="Video Preview"
            onHide={() => {
              setFeedbackVideoUrl(undefined);
            }}
            visible={typeof feedbackVideoUrl !== "undefined"}
          >
            <VideoPlayer
              options={{
                autoplay: false,
                controls: true,
                fluid: true,
                loop: false,
                preload: "auto",
              }}
              onReady={(player: Player) => {
                if (feedbackVideoUrl) {
                  const [url, meta] = feedbackVideoUrl.split("@");

                  // convert timestamps to numbers, then convert ms to seconds
                  const [start, end] = meta
                    .split("-")
                    .map((v) => Number(v).valueOf() / 1000);

                  // setup the source when it's ready
                  player.src({
                    src: url,
                    type: "application/x-mpegURL",
                  });
                  player.on("timeupdate", () => {
                    const currentTime = player.currentTime();
                    if (currentTime && currentTime >= end) {
                      player.pause();
                      player.currentTime(start);
                    }
                  });
                  player.currentTime(start);
                }
              }}
            />
          </Dialog>
          {feedback.map((feedback) => (
            <Panel key={feedback.prompt} header={feedback.prompt}>
              <p className="m-0">{feedback.response}</p>
              <DataTable
                value={feedback.segments.map((s) => ({
                  ...s,
                  durationSecs: (s.to - s.from) / 1000,
                }))}
              >
                <Column field="id" header="Id"></Column>
                <Column
                  field="transcriptVideoUploadId"
                  header="Video Id"
                ></Column>
                <Column field="from" header="Start"></Column>
                <Column field="to" header="End"></Column>
                <Column
                  field="durationSecs"
                  header="Duration (Seconds)"
                ></Column>
                <Column field="confidence" header="Confidence"></Column>
                <Column
                  header="Video Preview"
                  body={(_data, options) => (
                    <Button
                      label="Play Clip"
                      outlined
                      onClick={async () => {
                        const chunk = feedback.segments[options.rowIndex];
                        const transcriptVideoUploadId =
                          chunk.transcriptVideoUploadId;

                        // get the transcript
                        const transcript = await utils.transcript.getById.fetch(
                          { id: transcriptVideoUploadId },
                        );

                        // parse the videoUploadId
                        const videoUploadId = transcript.videoUploadId;

                        // env value will be like https://dev.api.active.smashcut.com
                        // so we modify it to get the user-content CDN url
                        const cdnHostname =
                          import.meta.env.VITE_API_HOST.replace(
                            ".api.",
                            "-user-cdn.content.",
                          );

                        // load the video url
                        setFeedbackVideoUrl(
                          `${cdnHostname}/video-upload/${videoUploadId}/hls/index.m3u8@${chunk.from}-${chunk.to}`,
                        );
                      }}
                    />
                  )}
                ></Column>
              </DataTable>
            </Panel>
          ))}
        </Card>
      )}
    </div>
  );
}

export default PromptDetail;
