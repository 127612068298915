import styles from "./VideoUploaderDialog.module.css";
import { Dialog } from "primereact/dialog";
import { useVideoUploader } from "./useVideoUploader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { ProgressBar } from "primereact/progressbar";
import { Upload } from "./VideoUploaderContext";
import { Button } from "primereact/button";

export function VideoUploaderDialog() {
  const { uploads, uploadDialogVisible, setUploadDialogVisible, addUpload } =
    useVideoUploader();
  //console.log("uploads", uploads);

  function progressBody(upload: Upload) {
    if (upload.state === "uploading") {
      return <ProgressBar value={upload.uploadProgress} />;
    }
    if (upload.state === "encoding") {
      return <ProgressBar mode="indeterminate" />;
    }
    if (upload.state === "complete") {
      return <ProgressBar value={100} color="success" />;
    }
    if (upload.state === "error") {
      return <ProgressBar value={100} color="error" />;
    }
    return null;
  }

  const actionsBody = (upload: Upload) => {
    return (
      <div>
        <Button
          icon="pi pi-download"
          aria-label="Download"
          title="Download"
          text
          onClick={() => window.alert("Todo Download \n" + upload.downloadUrl)}
        />
        <Button
          icon="pi pi-times"
          aria-label="Cancel Upload"
          title="Cancel Upload"
          text
          onClick={() => window.alert("Todo Cancel")}
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Uploads"
      visible={uploadDialogVisible}
      style={{ width: "50vw" }}
      onHide={() => setUploadDialogVisible(false)}
    >
      <DndProvider backend={HTML5Backend}>
        <TargetBox onDrop={addUpload} />
      </DndProvider>

      <DataTable value={uploads}>
        <Column field="name" header="Name" />
        <Column field="state" header="State" />
        <Column body={progressBody} header="Progress" />
        <Column body={actionsBody} header="Actions" />
      </DataTable>
    </Dialog>
  );
}

type TargetBoxProps = {
  onDrop: (item: File) => void;
};

export const TargetBox = ({ onDrop }: TargetBoxProps) => {
  function handleDropOrSelect({ files }: { files: File[] }) {
    console.log("dropped", files);
    for (const file of files) {
      onDrop(file);
    }
  }
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: handleDropOrSelect,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop],
  );

  const isActive = canDrop && isOver;
  const style = isActive
    ? styles.targetBoxActive
    : canDrop
    ? styles.targetBoxCanDrop
    : styles.targetBox;
  return (
    <div ref={drop} className={style}>
      <h2>Drag or Select Videos to Upload</h2>
      <FileUpload
        mode="basic"
        auto
        onSelect={handleDropOrSelect}
        accept="video/*"
      />
    </div>
  );
};
