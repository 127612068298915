import { VideoUploader } from "./VideoUploader";
import { makeVideoUploadService } from "./makeVideoUploadService";

export let videoUploader: VideoUploader | null = null;

export function getVideoUploader(
  apiUrl: string,
  videoBucket: string,
): VideoUploader {
  if (!videoUploader) {
    videoUploader = new VideoUploader(
      makeVideoUploadService(apiUrl, videoBucket),
    );
  }
  return videoUploader;
}
