/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { LearningObjective, trpc } from "@arena-active/trpc-client";

function ObjectiveDetail() {
  const [error, setError] = useState<Error | null>(null);
  const utils = trpc.useUtils();
  const { objectiveId } = useParams();
  const numberId = objectiveId ? parseInt(objectiveId) : -1;
  if (numberId == -1) setError(new Error("no id"));
  const { data: objectiveData, isLoading } = trpc.objective.get.useQuery({
    id: numberId,
  });

  const generateRubricMutation = trpc.rubric.generateRubric.useMutation();
  const generatePromptsMutation = trpc.objective.generatePrompts.useMutation();
  const updatePromptMutation = trpc.prompt.updatePrompt.useMutation();

  const navigate = useNavigate();
  const [objective, setObjective] = useState<LearningObjective>(
    {} as LearningObjective,
  );
  const [generatingRubric, setGeneratingRubric] = useState(false);
  const [generatingPrompts, setGeneratingPrompts] = useState(false);

  const generateRubric = async () => {
    try {
      setGeneratingRubric(true);
      const response = await generateRubricMutation.mutateAsync({
        learningObjectiveId: numberId,
        save: true,
      });
      setObjective((prevObjective: any) => ({
        ...prevObjective,
        rubric: response.data,
      }));
      setGeneratingRubric(false);
    } catch (err) {
      setError(new Error("error generating rubric"));
      console.error(err);
    }
  };

  const generatePrompts = async () => {
    try {
      setGeneratingPrompts(true);
      await generatePromptsMutation.mutateAsync({ id: numberId });
      utils.objective.get.invalidate();
      setGeneratingPrompts(false);
    } catch (err) {
      setError(new Error("error generating prompts"));
    }
  };

  useEffect(() => {
    setError(null);
    if (objectiveData) {
      setObjective(objectiveData);
    }
  }, [objectiveData]);

  if (isLoading)
    return (
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    );
  if (error) return <div>Error: {error.message}</div>;

  const onCellEditComplete = async (e: any) => {
    const { rowData, newRowData } = e;
    if (rowData !== newRowData) {
      try {
        await updatePromptMutation.mutateAsync({
          id: newRowData.id,
          prompt: newRowData.prompt,
        });
        utils.objective.get.invalidate();
      } catch (err) {
        console.error("error updating prompt", err);
      }
    }
  };

  const textEditor = (options: any) => {
    return (
      <InputText
        type="text"
        style={{ width: "100%" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const navigateToPrompt = (promptId: string) => {
    navigate(`prompt/${promptId}`);
  };

  const tryItButton = (rowData: any) => {
    return (
      <div>
        <Button
          label="Details"
          style={{ width: "100px" }}
          onClick={() => navigateToPrompt(rowData.id)}
        ></Button>
      </div>
    );
  };

  return (
    <Card>
      <Fieldset
        legend={`Objective: ${objective?.summary}`}
        toggleable
        collapsed={false}
        className="mb-4"
      >
        <p className="m-0">{objective?.description}</p>
      </Fieldset>
      <Fieldset legend="Rubric" toggleable collapsed={true} className="mb-4">
        {!objective?.rubric || objective.rubric.ratings.length <= 0 ? (
          <Button
            text
            label={generatingRubric ? "Generating" : "Generate"}
            onClick={generateRubric}
            loading={generatingRubric}
          ></Button>
        ) : (
          <DataTable
            editMode="cell"
            value={objective.rubric.ratings}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column key="rating" field="rating" header="Rating"></Column>
            <Column
              key="description"
              field="description"
              header="Description"
              editor={(options) => textEditor(options)}
              onCellEditComplete={onCellEditComplete}
            ></Column>
          </DataTable>
        )}
      </Fieldset>
      <Fieldset legend="Question Prompts" toggleable collapsed={true}>
        {!objective?.prompts || objective.prompts?.length <= 0 ? (
          (objective?.rubric?.ratings?.length ?? 0) > 0 ? (
            <Button
              text
              label={generatingPrompts ? "Generating" : "Generate"}
              onClick={generatePrompts}
              loading={generatingPrompts}
            />
          ) : (
            <p>To automatically generate prompts, first define a rubric</p>
          )
        ) : (
          <DataTable
            editMode="cell"
            value={objective?.prompts}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              key="prompt"
              field="prompt"
              header="Prompts"
              editor={(options) => textEditor(options)}
              onCellEditComplete={onCellEditComplete}
            ></Column>
            <Column body={tryItButton}></Column>
          </DataTable>
        )}
      </Fieldset>
    </Card>
  );
}

export default ObjectiveDetail;
