import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { trpc } from "@arena-active/trpc-client";
import { VIDEO_CLOUDFRONT_URL } from "../config";

type VideoRowItem = {
  id: number;
  name: string;
  owner: string;
  organization: string;
  status: string;
  hlsUrl: string;
};

const dummyData: VideoRowItem[] = [
  {
    id: 1,
    name: "video1",
    owner: "Me",
    organization: "My Organization",
    status: "complete",
    hlsUrl: "dummy-url",
  },
  {
    id: 2,
    name: "video2",
    owner: "John",
    organization: "My Organization",
    status: "complete",
    hlsUrl: "dummy-url",
  },
];

type OnHide = () => void;
type OnVideoSelected = (video: VideoRowItem) => void;
type VideoSelectProps = {
  onVideoSelected: OnVideoSelected;
};

export function VideoSelect({ onVideoSelected }: VideoSelectProps) {
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <div>
      <Button label="Select Video" onClick={() => setDialogVisible(true)} />
      {dialogVisible && (
        <VideoSelectDialog
          onVideoSelected={onVideoSelected}
          onHide={() => {
            setDialogVisible(false);
          }}
        />
      )}
    </div>
  );
}

type VideoSelectDialogProps = {
  onHide: OnHide;
  onVideoSelected: OnVideoSelected;
};

function VideoSelectDialog({
  onHide,
  onVideoSelected,
}: VideoSelectDialogProps) {
  const [videos, setVideos] = useState<VideoRowItem[]>(dummyData);
  const [selectedVideo, setSelectedVideo] = useState<VideoRowItem | null>(null);

  const videoListQuery = trpc.videoUpload.list.useQuery();
  useEffect(() => {
    if (videoListQuery.data) {
      console.log(videoListQuery.data);

      setVideos(
        videoListQuery.data.map((video) => ({
          id: video.id,
          name: video.originalFileName,
          owner: "Me",
          organization: "My Organization",
          status: video.status,
          hlsUrl: s3ToCloudfrontUrl(video.baseUrl + video.hlsUrl),
        })),
      );
    }
  }, [videoListQuery.data]);

  function handleSubmit() {
    if (selectedVideo) {
      onVideoSelected(selectedVideo);
      onHide();
    }
  }

  return (
    <Dialog
      header="Select Video"
      visible={true}
      modal={true}
      onHide={() => {
        onHide();
      }}
      style={{ width: "80vw" }}
    >
      <div className="flex flex-column gap-3">
        <div>
          <OwnerSelect />
        </div>
        <div>
          <DataTable
            value={videos}
            selectionMode="single"
            dataKey="id"
            sortField="id"
            sortOrder={-1}
            onSelectionChange={(e) => setSelectedVideo(e.value as VideoRowItem)}
            selection={selectedVideo}
            stripedRows
            scrollable
            scrollHeight="50vh"
          >
            <Column header="Id" field="id" sortable />
            <Column header="Name" field="name" sortable />
            <Column header="Status" field="status" sortable />
            <Column header="Owner" field="owner" sortable />
            <Column header="Organization" field="organization" sortable />
          </DataTable>
        </div>
        <div className="flex flex-row justify-content-center gap-3">
          <Button
            label="Submit"
            icon="pi pi-check"
            disabled={!selectedVideo}
            onClick={handleSubmit}
          />
          <Button
            label="Cancel"
            className="p-button-text"
            icon="pi pi-times"
            onClick={onHide}
          />
        </div>
      </div>
    </Dialog>
  );
}

function s3ToCloudfrontUrl(s3Url: string): string {
  return VIDEO_CLOUDFRONT_URL + "/" + s3Url.split("/").slice(3).join("/");
}

function OwnerSelect() {
  const options = [
    { label: "Me", value: "Me" },
    { label: "My Organization", value: "My Organization" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  return (
    <div className="flex flex-row gap-2">
      <label>Show videos uploaded by</label>
      <RadioGroup
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </div>
  );
}

type Option = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  options: Option[];
  selectedOption: Option;
  setSelectedOption: (option: Option) => void;
};

export default function RadioGroup({
  options,
  selectedOption,
  setSelectedOption,
}: RadioGroupProps) {
  return (
    <div className="card flex justify-content-center">
      <div className="flex flex-row gap-3">
        {options.map((option) => {
          return (
            <div key={option.value} className="flex align-items-center">
              <RadioButton
                inputId={option.value}
                name="option"
                value={option}
                onChange={(e) => setSelectedOption(e.value)}
                checked={selectedOption.value === option.value}
              />
              <label htmlFor={option.value} className="ml-2">
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
