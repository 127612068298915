import { ProgressBar } from "primereact/progressbar";
import { trpc } from "@arena-active/trpc-client";

import { OrderList } from "primereact/orderlist";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";

import { Button } from "primereact/button";

import { useEffect, useState } from "react";
import { AddChatExerciseDialog } from "./add-entity/AddChatExercise";

interface ActiveSessionExerciseProps {
  sessionId: number;
  lessonId?: number;
}

const EXERCISE_OPTIONS: string[] = ["Text", "Webcam", "Chat"];

export const ActiveSessionExercises: React.FC<ActiveSessionExerciseProps> = ({
  sessionId,
  lessonId,
}) => {
  const utils = trpc.useUtils();
  const [addExerciseType, setAddExerciseType] = useState<string>(
    EXERCISE_OPTIONS[0],
  );
  const [showAddChatExerciseDialog, setShowAddChatExerciseDialog] =
    useState(false);

  const [selectedPrompt, setSelectedPrompt] = useState<number>();

  const _lessonId = lessonId ? lessonId : -1;
  const {
    data: sessionData,
    isLoading,
    isError,
  } = trpc.activeSession.get.useQuery({ id: sessionId });

  type NonNullableType<T> = T extends null | undefined ? never : T;

  type ExerciseType = NonNullableType<typeof sessionData>["exercises"][number];
  const { data: lessonData } = trpc.lesson.getWithObjectivesAndPrompts.useQuery(
    { id: _lessonId },
  );
  const addExerciseMutation = trpc.activeSession.addExercise.useMutation();
  const deleteExerciseMutation = trpc.exercise.delete.useMutation();
  const [exercises, setExercises] = useState<ExerciseType[] | undefined>([]);

  useEffect(() => {
    setExercises(sessionData?.exercises);
  }, [sessionData]);

  const handleAddChatExerciseComplete = (added: boolean): void => {
    setShowAddChatExerciseDialog(false);
    if (added) {
      console.log("exercise added successfully");
      utils.activeSession.get.invalidate();
    } else {
      console.error("cancelled adding course");
    }
  };

  const getPrompts = () => {
    return (
      lessonData?.learningObjectives.flatMap((obj) =>
        obj.prompts.map((prompt) => ({
          label: prompt.prompt,
          value: prompt.id,
        })),
      ) || []
    );
  };

  const addExercise = async () => {
    if (selectedPrompt) {
      try {
        const responseType = addExerciseType == "Text" ? "text" : "video";
        await addExerciseMutation.mutateAsync({
          responseType,
          activeSessionId: sessionId,
          promptId: selectedPrompt,
        });
        utils.activeSession.get.invalidate();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const removeExercise = async (exerciseId: number) => {
    try {
      if (exerciseId) {
        await deleteExerciseMutation.mutateAsync({ id: exerciseId });
        utils.activeSession.get.invalidate();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading)
    return (
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    );
  if (isError) return <div>Error loading</div>;

  if (!sessionId || !lessonId) return <></>;

  const itemTemplate = (item: ExerciseType) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        {item.prompt?.prompt && (
          <span className="font-light text-400">{item.prompt?.prompt}</span>
        )}

        {item.chat?.description && (
          <span className="font-light text-400">
            {item.chat?.description.slice(0, 32)}
          </span>
        )}

        <span className="font-light text-400">
          ({item?.responseType} response)
        </span>
        <Button
          onClick={() => removeExercise(item?.id ?? -1)}
          icon="pi pi-times"
          rounded
          text
          severity="warning"
          aria-label="remove exercise"
        />
      </div>
    );
  };

  return (
    <>
      <AddChatExerciseDialog
        visible={showAddChatExerciseDialog}
        onHide={() => setShowAddChatExerciseDialog(false)}
        activeSessionId={sessionId}
        onComplete={handleAddChatExerciseComplete}
      />
      <div className="mt-3">
        <OrderList
          dataKey="id"
          value={exercises}
          onChange={(e) => setExercises(e.value)}
          itemTemplate={itemTemplate}
          header={`exercises ${
            (exercises?.length ?? 0) <= 0
              ? "(none added)"
              : `(${exercises?.length})`
          }`}
        ></OrderList>
      </div>
      <div className="flex align-items-center gap-2 mt-3">
        <span className="mr-2">Response type:</span>
        <SelectButton
          value={addExerciseType}
          onChange={(e) => setAddExerciseType(e.value)}
          options={EXERCISE_OPTIONS}
          className="flex-grow-0"
        />
        {addExerciseType !== "Chat" && (
          <Dropdown
            value={selectedPrompt}
            options={getPrompts()}
            onChange={(e) => setSelectedPrompt(e.value)}
            placeholder="select a prompt"
            className="flex-grow-0"
            style={{ width: "250px" }}
            filter
          />
        )}
        {addExerciseType !== "Chat" && (
          <Button
            label="Add Exercise"
            className="flex-grow-0"
            onClick={addExercise}
          />
        )}
        {addExerciseType === "Chat" && (
          <Button
            icon="pi pi-external-link"
            label="Create Chat Activity"
            className="flex-grow-0"
            onClick={() => setShowAddChatExerciseDialog(true)}
          />
        )}
      </div>
    </>
  );
};
