import styles from "./HeaderNav.module.css";
import { UserButton } from "@clerk/clerk-react";
import sessionsIcon from "../assets/sessionsIcon.png";
import { useNavigate } from "react-router-dom";
import { HeaderCreateMenu } from "./HeaderCreateMenu";
import { Button } from "primereact/button";
import { useContentStore } from "../useContentStore";

export const HeaderNav = () => {
  const navigate = useNavigate();
  const showSearch = useContentStore((state) => state.showSearch);
  return (
    <div className={styles.headerContainer}>
      <img
        src={sessionsIcon}
        alt="sessions icon"
        className={styles.sessionsIcon}
        onClick={() => navigate("/")}
      />
      <div className={styles.headerButtons}>
        <Button icon="pi pi-search" text label="Search" onClick={showSearch} />
        <HeaderCreateMenu />
      </div>
      <UserButton />
    </div>
  );
};
