import AmazonS3URI from "amazon-s3-uri";
import { makeEncodingClient } from "@arena-active/encoding-client";
import { makeStorageServices } from "../storage/makeStorageServices";
import { VideoUploadService } from "./VideoUploadService";
import { standaloneTRPCClient } from "@arena-active/trpc-client";

export function makeVideoUploadService(apiUrl: string, videoBucket: string) {
  const trpcClient = standaloneTRPCClient(apiUrl);
  const { client: storageClient } = makeStorageServices(apiUrl);
  const encodingClient = makeEncodingClient(apiUrl);
  const service: VideoUploadService = {
    createVideoUpload: async (file: File): Promise<number> => {
      try {
        const output = await trpcClient.videoUpload.create.mutate({
          originalFileName: file.name,

          status: "created",
        });
        return output.data.id;
      } catch (error) {
        console.error("Failed to create video upload", error);
        throw new Error("Failed to create video upload");
      }
    },

    getVideoUpload: async (id: number) => {
      try {
        const videoUpload = await trpcClient.videoUpload.get.query({ id });
        if (!videoUpload) {
          throw new Error("Video upload not found");
        }
        return videoUpload;
      } catch (error) {
        console.error("Failed to get video upload", error);
        throw new Error("Failed to get video upload");
      }
    },
    listVideoUploads: async () => {
      try {
        const videoUploads = await trpcClient.videoUpload.list.query();
        return videoUploads;
      } catch (error) {
        console.error("Failed to list video uploads", error);
        throw new Error("Failed to list video uploads");
      }
    },

    updateVideoUpload: async (
      id: number,
      status: string,
      optionalUpdates?: {
        duration?: number;
        sourceUrl?: string;
        audioUrl?: string;
        baseUrl?: string;
        dashUrl?: string;
        hlsUrl?: string;
        spriteUrl?: string;
        thumbnailUrl?: string;
        vttUrl?: string;
      },
    ): Promise<void> => {
      try {
        await trpcClient.videoUpload.update.mutate({
          id,
          status,
          ...optionalUpdates,
        });
      } catch (error) {
        console.error("Failed to update video upload", error);
        throw new Error("Failed to update video upload");
      }
    },
    uploadFile: async (
      file: File,
      filename: string,
      onProgress: (progress: number) => void,
    ) => {
      try {
        const response = await storageClient.uploadLargeBlob(
          file,
          filename,
          videoBucket,
          (loaded, total) => {
            const progress = (loaded / total) * 100;
            onProgress(progress);
          },
        );
        console.log("response", response);

        return {
          success: true,
          downloadUrl: response,
          message: "upload success",
        };
      } catch (error) {
        console.error("Failed to upload file", error);
        return {
          success: false,
          message: "Failed to upload file",
        };
      }
    },
    encodeFile: async (url: string, onStateChange: (state: string) => void) => {
      const { bucket, key } = AmazonS3URI(url);
      if (!bucket || !key) {
        throw new Error("Invalid S3 URI");
      }
      const inputKey = key;
      const videoId = key.split("/").slice(0, -1).join("/");
      console.log("bucket", bucket, "key", key, "videoId", videoId);

      const response = await encodingClient.encodeFile(
        bucket,
        inputKey,
        videoId,
        onStateChange,
      );
      console.log("response", response);
      return response;
    },
  };
  return service;
}
