import { trpc, Course } from "@arena-active/trpc-client";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { AddEntityProps } from "./AddEntityProps";
import { SelectLessonCourse } from "../SelectLessonCourse";

export const AddLessonPart: React.FC<AddEntityProps> = ({ onComplete }) => {
  const addLessonMutation = trpc.lesson.create.useMutation();
  const [course, setCourse] = useState<Course>();

  const [lessonTitle, setLessonTitle] = useState("");
  const [createdLessonId, setCreatedLessonId] = useState<number | undefined>();

  useEffect(() => {
    if (addLessonMutation.isSuccess) {
      onComplete(true, createdLessonId);
    }
  }, [addLessonMutation.isSuccess, createdLessonId, onComplete]);

  async function addLesson() {
    if (course?.id) {
      const { data } = await addLessonMutation.mutateAsync({
        title: lessonTitle,
        courseId: course?.id,
      });
      data.id && setCreatedLessonId(data.id);
    }
  }

  return (
    <>
      {!addLessonMutation.isLoading ? (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <SelectLessonCourse
            contentType="lesson"
            courseSelectionChanged={setCourse}
          />
          <InputText
            value={lessonTitle}
            onChange={(e) => setLessonTitle(e.target.value)}
            style={{ marginBottom: "1rem" }}
            placeholder="Lesson Part Title"
          />
          <div>
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => onComplete(false)}
                className="p-button-text"
              />
              <Button
                label="Add"
                icon="pi pi-check"
                disabled={!lessonTitle || !course?.id}
                onClick={addLesson}
                autoFocus
              />
            </div>
          </div>
        </div>
      ) : (
        <ProgressSpinner />
      )}
    </>
  );
};
