import { Course, trpc } from "@arena-active/trpc-client";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

/**
 * SelectLessonCourseProps
 * @param id - lesson or activeLesson id. If provided the lesson will be updated with the selected course automatically
 * @param currentCourseId - current course id for the lesson or activeLesson - if present the dropdown will be set to this course
 * @param contentType - lesson or activeLesson
 * @param courseSelectionChanged - callback function when course is selected
 */
type SelectLessonCourseProps = {
  id?: number;
  currentCourseId?: number;
  contentType: "lesson" | "activeLesson";
  courseSelectionChanged?: (course: Course) => void;
};

export const SelectLessonCourse: React.FC<SelectLessonCourseProps> = ({
  id,
  contentType,
  currentCourseId,
  courseSelectionChanged,
}) => {
  const [selectedCourse, setSelectedCourse] = useState<Course | null>();
  const { data: courses } = trpc.course.list.useQuery({ includeCounts: false });
  const updateLesson = trpc.lesson.update.useMutation();
  const updateActiveLesson = trpc.activeLesson.update.useMutation();

  useEffect(() => {
    if (courses && currentCourseId !== undefined) {
      const currentCourse = courses.find(
        (course) => course.id === currentCourseId,
      );
      setSelectedCourse(currentCourse ?? null);
    }
  }, [courses, currentCourseId]);

  const handleDropdownChange = (e: { value: Course }) => {
    setSelectedCourse(e.value);
    if (courseSelectionChanged) {
      courseSelectionChanged(e.value);
    }
    if (id) {
      if (contentType === "lesson") {
        updateLesson.mutateAsync({ id, courseId: e.value.id });
      } else if (contentType === "activeLesson") {
        updateActiveLesson.mutateAsync({ id, courseId: e.value.id });
      }
    }
  };

  return (
    <>
      <Dropdown
        options={courses ?? []}
        value={selectedCourse}
        onChange={handleDropdownChange}
        optionLabel="title"
        placeholder="Select a Course"
        filter
      />
    </>
  );
};
