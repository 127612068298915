import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { trpc } from "@arena-active/trpc-client";
import { useNavigate } from "react-router-dom";
import { useContentStore } from "../useContentStore";
import styles from "./SearchDialog.module.css";
import { useDebounce, useLocalStorage } from "primereact/hooks";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface SearchResult {
  id: number;
  type: string;
  link: string;
  title: string;
}

type RecentClick = SearchResult & {
  timestamp: number;
};

type RecentsMap = {
  [key: string]: RecentClick;
};

function SearchDialog() {
  const [searchTerm, debouncedValue, setSearchTerm] = useDebounce("", 1000);
  const [recents, setRecents] = useLocalStorage<RecentsMap>({}, "recents");
  const navigate = useNavigate();
  const isOpen = useContentStore((state) => state.searchOpen);
  const hideSearch = useContentStore((state) => state.hideSearch);

  const searchQuery = trpc.search.search.useQuery(
    {
      term: debouncedValue,
    },
    { enabled: debouncedValue?.length > 2 },
  );

  // useEffect(() => {
  //   setRecents([])
  // }, []);

  const goToResult = (item: SearchResult) => {
    const newRecents: RecentsMap = { ...recents };
    newRecents[item.type + item.id] = {
      link: item.link,
      type: item.type,
      id: item.id,
      title: item.title,
      timestamp: new Date().getTime(),
    };

    setRecents(newRecents);

    navigate(`/${item.link}/${item.id}`);
    hideSearch();
  };

  const filteredRecents = Object.keys(recents).reduce((acc, val) => {
    if (recents[val].title.search(new RegExp(searchTerm, "i")) >= 0) {
      acc[val] = recents[val];
    }
    return acc;
  }, {} as RecentsMap);

  return (
    <Dialog
      className={styles.dialog}
      contentClassName={styles.content}
      header="Search"
      visible={isOpen}
      style={{ width: "70vw", height: "70vh" }}
      onHide={() => hideSearch()}
    >
      <p className="m-0">
        <InputText
          value={searchTerm}
          autoFocus
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </p>
      {Object.keys(filteredRecents).length > 0 && (
        <div>
          <h4>Recently viewed items</h4>
          <div>
            {Object.keys(filteredRecents)
              .sort((a, b) => recents[a].timestamp - recents[b].timestamp)
              .reverse()
              .slice(0, 5)
              .map(
                (recent) =>
                  recent?.length > 0 && (
                    <div
                      key={recent}
                      className={styles.recent}
                      onClick={() => goToResult(recents[recent])}
                    >
                      {recents[recent].title}
                    </div>
                  ),
              )}
          </div>
        </div>
      )}
      {searchTerm.length > 2 && searchQuery.isLoading && <ProgressSpinner />}
      {searchQuery.data && (
        <DataTable
          className={styles.table}
          size="small"
          scrollable
          scrollHeight="flex"
          value={searchQuery.data}
          onRowClick={(event) => goToResult(event.data as SearchResult)}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="type" header="Type" />
          <Column field="title" header="Title" />
        </DataTable>
      )}
    </Dialog>
  );
}

export default SearchDialog;
