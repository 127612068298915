import styles from "./App.module.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme

import { PrimeReactProvider } from "primereact/api";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { trpc } from "@arena-active/trpc-client";
import {
  SignIn,
  SignedIn,
  SignedOut,
  useAuth,
  useUser,
} from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { useEffect, useState } from "react";
import ActiveLessonDetail from "./components/ActiveLessonDetails";
import Breadcrumbs from "./components/BreadCrumbs";
import CourseDetail from "./components/CourseDetail";
import Courses from "./components/Courses";
import { HeaderNav } from "./components/HeaderNav";
import Home from "./components/Home";
import LessonDetail from "./components/LessonDetail";
import ObjectiveDetail from "./components/ObjectiveDetail";
import PromptDetail from "./components/PromptDetail";
import PromptsList from "./components/PromptsList";
import SearchDialog from "./components/SearchDialog";
import { VideoUploadsProvider } from "./components/VideoUploaderContext";
import { VideoUploaderDialog } from "./components/VideoUploaderDialog";
import { useContentStore } from "./useContentStore";
import { API_URL, VIDEO_BUCKET, VIDEO_CLOUDFRONT_URL } from "./config";

export interface ContentStore {
  searchOpen: boolean;
  showSearch: () => void;
  hideSearch: () => void;
}

function App() {
  const { user } = useUser();
  const { getToken } = useAuth();
  console.log("Current user", user);
  console.log("config", { API_URL, VIDEO_BUCKET, VIDEO_CLOUDFRONT_URL });

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: API_URL,
          async headers() {
            const token = await getToken();

            return {
              Authorization: `Bearer ${token}`,
            };
          },
        }),
      ],
    }),
  );

  const showSearch = useContentStore((state) => state.showSearch);

  useEffect(() => {
    document.onkeydown = function (evt) {
      evt = evt || window.event;

      if (evt.ctrlKey && evt.key === "f") {
        showSearch();
      }
    };

    return () => {
      document.onkeydown = null;
    };
  }, [showSearch]);

  function Layout() {
    return (
      <div>
        <HeaderNav />
        <SearchDialog />
        <Breadcrumbs styles={styles} />
        <Outlet />
      </div>
    );
  }
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="/" element={<Outlet />}>
          {/* Redirect from / to /course for now */}
          <Route index element={<Navigate replace to="/course" />} />
          <Route path="/oldhome" element={<Home />} />
          <Route path="/course" element={<Outlet />}>
            <Route index element={<Courses />} />
            <Route path=":courseId" element={<Outlet />}>
              <Route index element={<CourseDetail />} />
              <Route
                path="activeLesson/:activeLessonId"
                element={<ActiveLessonDetail />}
              />
              <Route path="lesson/:lessonId" element={<Outlet />}>
                <Route index element={<LessonDetail />} />

                <Route path="objective/:objectiveId" element={<Outlet />}>
                  <Route path="prompt" element={<Outlet />}>
                    <Route index element={<PromptsList />} />

                    <Route path=":promptId" element={<PromptDetail />} />
                  </Route>

                  <Route index element={<ObjectiveDetail />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/course/:courseId" element={<CourseDetail />} />
          <Route
            path="/activeLesson/:activeLessonId"
            element={<ActiveLessonDetail />}
          />
          <Route path="/lesson/:lessonId" element={<LessonDetail />} />
          <Route path="/objective/:objectiveId" element={<ObjectiveDetail />} />
          <Route path="/prompt/:promptId" element={<PromptDetail />} />
        </Route>
        ,
      </Route>,
    ),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider>
          <SignedOut>
            <div className={styles.centeredContainer}>
              <SignIn />
            </div>
          </SignedOut>
          <SignedIn>
            <VideoUploadsProvider>
              <div className={styles.container}>
                <RouterProvider router={router} />
                <VideoUploaderDialog />
              </div>
            </VideoUploadsProvider>
          </SignedIn>
        </PrimeReactProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
