import { EncodeFileResponse } from "./types.js";
import type { IClientEncodingService } from "./makeEncodingServiceClient.js";

type OnStateChange = (state: string) => void;

export function EncodingClient(service: IClientEncodingService) {
  console.log("Creating encoding client", service);

  return {
    encodeFile: async (
      bucket: string,
      inputKey: string,
      videoId: string,
      onStateChange: OnStateChange,
    ): Promise<EncodeFileResponse> => {
      try {
        console.log("Encoding file", bucket, inputKey, videoId);
        onStateChange("encoding");
        const start = await service.startEncoding({
          bucket,
          inputKey,
          videoId,
        });
        console.log("start", start);
        if (start.error) {
          onStateChange("error");
          return {
            success: false,
            message: "Error starting encoding",
          };
        }
        do {
          await new Promise((resolve) => setTimeout(resolve, 10000));
          const status = await service.getEncodingStatus({
            jobId: start.jobId,
          });
          console.log("status", status);
          if (status.error) {
            onStateChange("error");
            return {
              success: false,
              message: "Error encoding video",
            };
          }
          if (status.status === "COMPLETE") {
            onStateChange("postprocessing");
            console.info("Video encoded", "finishEncoding");
            const finish = await service.finishEncoding({
              jobId: start.jobId,
              bucket,
              videoId,
            });
            if (finish.error) {
              onStateChange("error");
              return {
                success: false,
                message: "Error finishing encoding",
              };
            }
            if (finish.urls) {
              onStateChange("complete");
              return {
                success: true,
                message: "Video encoded",
                duration: finish.duration || 0,
                urls: finish.urls,
              };
            }
          }
        } while (true); // eslint-disable-line no-constant-condition
      } catch (error) {
        console.error("Error encoding file", error);
        onStateChange("error");
        return {
          success: false,
          message: "Error encoding video",
        };
      }
    },
  };
}
