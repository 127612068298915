import React, { KeyboardEvent, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import styles from "./ChatInput.module.css";
import "primeicons/primeicons.css";

interface ChatInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend, disabled }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (disabled) {
      return;
    }
    onSend(message);
    setMessage("");
  };

  // TODO(ed): Set up to send on enter - this may not be what we want.
  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.altKey) {
      e.preventDefault();

      if (message.trim() === "") return;
      handleSend();
    }
  };

  return (
    <div className={`${styles.chat_input} foobar`}>
      <InputTextarea
        autoResize
        autoFocus
        placeholder="Type a message..."
        value={message}
        onKeyDown={onKeyDown}
        onChange={(e) => setMessage(e.target.value)}
        style={{ flexGrow: 1, marginRight: "10px" }}
      />

      <Button
        onClick={handleSend}
        icon="pi pi-send"
        rounded
        outlined
        disabled={message === ""}
        severity="secondary"
        aria-label="Bookmark"
      />
    </div>
  );
};

export default ChatInput;
