import styles from "./AddChatExercise.module.css";
import { trpc } from "@arena-active/trpc-client";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { AddEntityProps } from "./AddEntityProps";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Dialog, DialogProps } from "primereact/dialog";
import { Chat, createGUID } from "@arena-active/client-lib";

interface AddChatExerciseProps {
  description?: string;
  prompt?: string;
  onDescriptionChange: (description: string) => void;
  onPromptChange: (prompt: string) => void;
}

const TestChat: React.FC<{ prompt: string; description: string }> = ({
  prompt,
  description,
}) => {
  const { mutateAsync } = trpc.userAiChat.testChat.useMutation();

  return (
    <Chat
      messageHandler={(userInput) =>
        mutateAsync({
          systemPrompt: prompt,
          description,
          uid: createGUID(),
          messages: [
            {
              type: "HUMAN",
              content: userInput,
            },
          ],
        }).then((res) => [{ ...res, timestamp: Date.now(), type: "ASSISTANT" }])
      }
    />
  );
};

const RagChat: React.FC<unknown> = () => {
  const { mutateAsync } = trpc.userAiChat.ragChat.useMutation();

  return (
    <Chat
      messageHandler={(userInput) =>
        mutateAsync({
          query: userInput,
        }).then((res) => [
          {
            uid: createGUID(),
            timestamp: Date.now(),
            content: res.answer,
            type: "ASSISTANT",
          },
        ])
      }
    />
  );
};

export const AddChatExercise: React.FC<AddChatExerciseProps> = ({
  description,
  prompt,
  onDescriptionChange,
  onPromptChange,
}) => {
  return (
    <TabView
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
      panelContainerStyle={{ flexGrow: 1, padding: 0, margin: 0 }}
    >
      <TabPanel header="Configure" contentClassName={styles.tabPanel}>
        <InputTextarea
          rows={2}
          value={description}
          autoResize
          onChange={(e) => onDescriptionChange(e.target.value)}
          placeholder="Chat Activity Description"
          style={{ marginBottom: "1rem", width: "100%" }}
        />
        <InputTextarea
          rows={5}
          value={prompt}
          autoResize
          onChange={(e) => onPromptChange(e.target.value)}
          placeholder="LLM Chat Prompt"
        />
      </TabPanel>
      <TabPanel header="Basic Test" contentClassName={styles.tabPanel}>
        <TestChat
          prompt={prompt ?? "You are a helpful assistant."}
          description={description ?? "A helpful assistant."}
        />
      </TabPanel>
      <TabPanel header="Rag Test" contentClassName={styles.tabPanel}>
        <RagChat />
      </TabPanel>
    </TabView>
  );
};

interface AddChatExerciseDialogProps
  extends AddEntityProps,
    Omit<DialogProps, "header"> {
  activeSessionId: number;
}

export const AddChatExerciseDialog: React.FC<AddChatExerciseDialogProps> = (
  props: AddChatExerciseDialogProps,
) => {
  const [description, setDescription] = useState<string>("");
  const [chatPrompt, setChatprompt] = useState<string>("");
  const { visible, onHide, style, activeSessionId, onComplete } = props;

  const addExerciseMutation = trpc.exercise.createChatExercise.useMutation();
  const [createdExerciseId, setCreatedExerciseId] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (addExerciseMutation.isSuccess) {
      onComplete(true, createdExerciseId);
    }
  }, [addExerciseMutation.isSuccess, createdExerciseId, onComplete]);

  async function addExercise() {
    if (activeSessionId) {
      const exercise = await addExerciseMutation.mutateAsync({
        description,
        chatPrompt,
        activeSessionId,
      });
      exercise.id && setCreatedExerciseId(exercise.id);
    }
  }
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => onComplete(false)}
        className="p-button-text"
      />
      <Button label="Add" icon="pi pi-check" onClick={addExercise} autoFocus />
    </div>
  );
  return (
    <Dialog
      visible={visible}
      header={"Add Chat Activity"}
      onHide={onHide}
      style={{ width: "80vw", height: "80vh", ...style }}
      footer={footerContent}
    >
      {addExerciseMutation.isLoading ? (
        <ProgressSpinner />
      ) : (
        <AddChatExercise
          onDescriptionChange={setDescription}
          onPromptChange={setChatprompt}
          description={description}
          prompt={chatPrompt}
        />
      )}
    </Dialog>
  );
};
