import { VideoUploadService } from "./VideoUploadService";
import { VideoUploaderJob } from "./VideoUploaderJob";

export class VideoUploader extends EventTarget {
  jobs: VideoUploaderJob[] = [];
  service: VideoUploadService;
  prefix: string;

  constructor(service: VideoUploadService, prefix: string = "video-upload") {
    super();
    this.service = service;
    this.prefix = prefix.endsWith("/") ? prefix : prefix + "/";
    console.log("VideoUploader created", this.prefix, prefix);
  }

  public getState() {
    console.log("Getting state", this.jobs);

    return {
      state: this.jobs.some((job) => job.isBusy()) ? "busy" : "idle",
      jobs: this.jobs.map((job) => job.getProps()),
    };
  }

  public async upload(file: File) {
    const id = await this.service.createVideoUpload(file);
    const ending = file.name.split(".").pop();
    const name = this.prefix + id + "/source." + ending;

    console.log("Uploading video", name, file);

    const job = new VideoUploaderJob(id, name, file, this.service);
    job.addEventListener("stateChange", this.jobStateChangeListener);
    this.jobs.push(job);
    console.log("Job added", this.jobs, this.isBusy());

    if (!this.isBusy()) {
      await this.uploadNext();
    }
  }

  public isBusy() {
    return this.jobs.some((job) => job.isBusy());
  }

  public async uploadNext() {
    const job = this.jobs.find((job) => job.isPending());
    if (job) {
      await job.run();
    }
  }

  private jobStateChangeListener = (e: Event) => {
    const job = e.target as unknown as VideoUploaderJob;
    console.log("Job state change", job.state);
    if (job.isDone()) {
      job.removeEventListener("stateChange", this.jobStateChangeListener);
      this.uploadNext();
    }
    this.dispatchEvent(new Event("stateChange"));
  };
}
