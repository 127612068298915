/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { trpc, ActiveLesson } from "@arena-active/trpc-client";

export const ActiveLessonList: React.FC<{ courseId?: number }> = ({
  courseId,
}) => {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const [selectedActiveLessons, setSelectedActiveLessons] = useState<
    ActiveLesson[]
  >([]);

  const [showAddActiveLessonDialog, setShowAddActiveLessonDialog] =
    useState(false);
  const [showDeleteActiveLessonsDialog, setShowDeleteActiveLessonsDialog] =
    useState(false);

  const [creatingActiveLesson, setCreatingActiveLesson] = useState(false);
  const [newActiveLesson, setNewActiveLesson] = useState({ title: "" });
  const [deletionStatus, setDeletionStatus] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [error, setError] = useState<Error | null>(null);

  const activeLessonListQuery = trpc.activeLesson.list.useQuery({ courseId });
  const addActiveLessonMutation = trpc.activeLesson.create.useMutation();
  const deleteManyMutation = trpc.activeLesson.deleteMany.useMutation();

  if (!activeLessonListQuery.data) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  function sessionCountTemplate(rowData: any) {
    return rowData.activeSessions ? rowData.activeSessions.length : 0;
  }

  function gotoActiveLesson(id: number) {
    navigate(`/activeLesson/${id}`);
  }
  function viewActiveLessonTemplate(activeLesson: any) {
    return (
      <Button
        link
        label="view"
        onClick={() => navigate(`activeLesson/${activeLesson.id}`)}
      ></Button>
    );
  }

  async function addActiveLesson() {
    setCreatingActiveLesson(true);
    try {
      if (courseId === undefined) throw new Error("courseId is undefined");
      await addActiveLessonMutation.mutateAsync({
        title: newActiveLesson.title,
        courseId,
      });
      setShowAddActiveLessonDialog(false);
      utils.activeLesson.list.invalidate();
    } catch (error) {
      setError({
        name: "error adding active lesson",
        message: addActiveLessonMutation.error?.message ?? "",
      });
    } finally {
      setCreatingActiveLesson(false);
    }
  }

  async function deleteSelectedActiveLessons() {
    try {
      setDeletionStatus(
        `deleting ${selectedActiveLessons.length} active lesson${
          selectedActiveLessons.length > 1 ? "s" : ""
        }`,
      );

      await deleteManyMutation.mutateAsync(
        selectedActiveLessons.map((lesson) => lesson.id),
      );

      utils.activeLesson.list.invalidate();
      setSelectedActiveLessons([]);
      setShowDeleteActiveLessonsDialog(false);
      setDeletionStatus(null);
    } catch (error) {
      console.error("Error: active lessons were not deleted:", error);
      setDeletionStatus(`Error deleting active lessons`);
    }
  }

  const addActiveLessonFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowAddActiveLessonDialog(false)}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => addActiveLesson()}
        autoFocus
      />
    </div>
  );

  const deleteActiveLessonFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowDeleteActiveLessonsDialog(false)}
        className="p-button-text"
      />
      <Button
        label="Delete"
        icon="pi pi-check"
        onClick={() => deleteSelectedActiveLessons()}
        autoFocus
      />
    </div>
  );

  return (
    <div>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "1rem",
            padding: "0 1rem",
          }}
        >
          <span style={{ marginRight: "10px" }}>edit</span>
          <InputSwitch
            checked={isEditMode}
            onChange={() => setIsEditMode(!isEditMode)}
          />
        </div>

        <DataTable
          dataKey="id"
          value={activeLessonListQuery.data?.activeLessons}
          tableStyle={{ minWidth: "50rem" }}
          rowClassName={() => ({ clickableRow: true })}
          onRowClick={
            isEditMode ? undefined : (e) => gotoActiveLesson(e.data.id)
          }
          onRowDoubleClick={(e) => gotoActiveLesson(e.data.id)}
          selectionMode={isEditMode ? "multiple" : null}
          selection={selectedActiveLessons}
          onSelectionChange={(e: any) => setSelectedActiveLessons(e.value)}
        >
          <Column
            selectionMode={isEditMode ? "multiple" : undefined}
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column field="title" header="Title"></Column>
          <Column
            field="activeSessions"
            header="# Active Sessions"
            body={sessionCountTemplate}
          ></Column>
          <Column field="autoEnrol" header="Supports Auto-Enroll"></Column>
          <Column body={viewActiveLessonTemplate}></Column>
        </DataTable>
        <div className="pt-4">
          <Button
            icon="pi pi-external-link"
            onClick={() => setShowAddActiveLessonDialog(true)}
            label="Add Active Lesson"
          ></Button>
          {selectedActiveLessons?.length > 0 && (
            <Button
              severity="warning"
              onClick={() => setShowDeleteActiveLessonsDialog(true)}
              label={`Delete ${
                selectedActiveLessons.length
              } Selected Active Lesson${
                selectedActiveLessons.length > 1 ? "s" : ""
              }`}
              className="m-10"
            ></Button>
          )}
        </div>
      </Card>
      <Dialog
        visible={showDeleteActiveLessonsDialog}
        onHide={() => setShowDeleteActiveLessonsDialog(false)}
        header={`Delete Active Lesson${
          selectedActiveLessons.length > 1 ? "s" : ""
        }`}
        footer={deleteActiveLessonFooter}
        style={{ width: "50vw", height: "50vh" }}
      >
        {deletionStatus ? (
          <div>{deletionStatus}</div>
        ) : (
          <div>
            Deleting this active lesson will delete all associated data:
            <ul>
              <li>Active Sessions</li>
              <li>Activities (and associated data)</li>
            </ul>
          </div>
        )}
      </Dialog>
      <Dialog
        visible={showAddActiveLessonDialog}
        onHide={() => setShowAddActiveLessonDialog(false)}
        header="Add Active Lesson"
        footer={addActiveLessonFooter}
        style={{ width: "75vw", height: "75vh" }}
      >
        {!creatingActiveLesson ? (
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <InputText
              onChange={(e) =>
                setNewActiveLesson((activeLessonState) => ({
                  ...activeLessonState,
                  title: e.target.value,
                }))
              }
              style={{ marginBottom: "1rem" }}
            />
          </div>
        ) : (
          <ProgressSpinner />
        )}
      </Dialog>
    </div>
  );
};

export default ActiveLessonList;
