/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import PlainTextEditor from "./PlainTextEditor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import styles from "./LessonList.module.css";
import { Button } from "primereact/button";
import { LearningObjective } from "../types";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { trpc, Lesson } from "@arena-active/trpc-client";
import { Fieldset } from "primereact/fieldset";
import { VideoPlayer, Player } from "@arena-active/video-player";
import { SelectButton } from "primereact/selectbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectLessonCourse } from "./SelectLessonCourse";
import { ConfirmWipeDialog } from "./ConfirmWipeDialog";
import { VideoSelect } from "./VideoSelect";

function LessonDetail() {
  const lessonTypeOptions = ["Video Lesson", "Lesson Page"];
  const [lessonTypeSelection, setLessonTypeSelection] = useState(
    lessonTypeOptions[0],
  );
  const playerRef = useRef<Player | null>(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const utils = trpc.useUtils();
  const [error, setError] = useState<Error | null>(null);
  const { lessonId } = useParams();
  const numberId = lessonId ? parseInt(lessonId) : -1;
  if (numberId == -1) setError(new Error("no id"));
  const { data: lessonData, isLoading } = trpc.lesson.get.useQuery({
    id: numberId,
  });
  const createObjectiveMutation = trpc.objective.createObjective.useMutation();
  const generateObjectivesMutation =
    trpc.lesson.generateObjectives.useMutation();

  const navigate = useNavigate();
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [generatingObjectives, setGeneratingObjectives] = useState(false);

  // tab state
  const getInitialTabIndex = () => {
    const savedIndex = localStorage.getItem("lessonDetailActiveTabIndex");
    if (savedIndex !== null) {
      return parseInt(savedIndex);
    }
    return 0; // Default to 0 if there is no saved index
  };

  const [activeTabIndex, setActiveTabIndex] = useState(getInitialTabIndex);

  useEffect(() => {
    localStorage.setItem(
      "lessonDetailActiveTabIndex",
      activeTabIndex.toString(),
    );
  }, [activeTabIndex]);

  const [isObjectivesEditMode, setIsObjectivesEditMode] = useState(false);
  const [selectedObjectives, setSelectedObjectives] = useState<
    LearningObjective[]
  >([]);
  const [deletionStatus, setDeletionStatus] = useState<string | null>(null);
  const [showAddObjectiveDialog, setShowAddObjectiveDialog] = useState(false);
  const [showDeleteObjectivesDialog, setShowDeleteObjectivesDialog] =
    useState(false);
  const [creatingObjective, setCreatinObjective] = useState(false);
  const [newObjective, setNewObjective] = useState({
    summary: "",
    description: "",
  });
  const deleteManyMutation = trpc.objective.deleteMany.useMutation();
  const deleteAllForLessonMutation =
    trpc.objective.deleteAllForLesson.useMutation();

  const updateLearningObjectiveMutation =
    trpc.objective.updateObjective.useMutation();

  const [content, setContent] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [posterUrl, setPosterUrl] = useState<string>("");
  const [lessonPageUrl, setLessonPageUrl] = useState<string>("");
  const [confirmWipeVisible, setConfirmWipeVisible] = useState<boolean>(false);

  useEffect(() => {
    if (lessonData) {
      setLesson(lessonData.lesson);
      setContent(lessonData.lesson?.content ?? "");
      setVideoUrl(lessonData.lesson?.videoUrl ?? "");
      setPosterUrl(lessonData.lesson?.posterUrl ?? "");
      setLessonPageUrl(lessonData.lesson?.lessonPageUrl ?? "");
    }
  }, [lessonData, setContent, setVideoUrl]);

  const updateLessonMutation = trpc.lesson.update.useMutation();

  const updateLesson = async () => {
    try {
      await updateLessonMutation.mutateAsync({
        id: lesson?.id ?? -1,
        title: lesson?.title ?? "",
        content: content ?? "",
        videoUrl: videoUrl ?? "",
        posterUrl: posterUrl ?? "",
        lessonPageUrl: lessonPageUrl ?? "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isPlayerReady && videoUrl) {
      playerRef.current?.src({ src: videoUrl, type: "application/x-mpegURL" });
    }
  }, [videoUrl, isPlayerReady]);

  async function deleteSelectedObjectives() {
    try {
      await deleteManyMutation.mutateAsync(
        selectedObjectives.map((objective) => objective.id),
      );
      utils.lesson.get.invalidate();
      setDeletionStatus(`deleted: ${selectedObjectives.length} objectives`);
      setSelectedObjectives([]);
      setDeletionStatus(null);
      setShowDeleteObjectivesDialog(false);
    } catch (err) {
      setError({
        name: "objectives deletion failed",
        message: "failed to delete objectives",
      });
      console.error(err);
    }
  }

  async function deleteAllObjectives() {
    try {
      await deleteAllForLessonMutation.mutateAsync({
        lessonId: lesson?.id || 0,
      });
      utils.lesson.get.invalidate();
      setSelectedObjectives([]);
      setDeletionStatus(null);
      setConfirmWipeVisible(false);
    } catch (err) {
      setError({
        name: "objectives deletion failed",
        message: "failed to delete objectives",
      });
      console.error(err);
    }
  }

  const deleteObjectiveFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowDeleteObjectivesDialog(false)}
        className="p-button-text"
      />
      <Button
        label="Delete"
        icon="pi pi-check"
        onClick={() => deleteSelectedObjectives()}
        autoFocus
      />
    </div>
  );

  const addObjectiveFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowAddObjectiveDialog(false)}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => addObjective()}
        autoFocus
      />
    </div>
  );

  async function addObjective() {
    setCreatinObjective(true);
    if (!lesson?.id) {
      setError(new Error("no lesson specified"));
    } else {
      await createObjectiveMutation.mutateAsync({
        lessonId: lesson?.id ?? -1,
        summary: newObjective.summary,
        description: newObjective.description,
      });
      utils.lesson.get.invalidate();
      setShowAddObjectiveDialog(false);
      setCreatinObjective(false);
    }
  }

  if (error) return <div>Error: {error.message}</div>;
  if (isLoading) return <div>Loading...</div>;

  async function generateObjectives() {
    setGeneratingObjectives(true);
    const _lessonId = lessonId && parseInt(lessonId);
    if (_lessonId) {
      try {
        await generateObjectivesMutation.mutateAsync({ id: _lessonId });
        setActiveTabIndex(1);
        setGeneratingObjectives(false);
        utils.lesson.get.invalidate();
      } catch (err) {
        setError(new Error("error generating objectives"));
        setGeneratingObjectives(false);
      }
    }
  }

  const onCellEditComplete = async (e: any) => {
    const { rowData, newRowData } = e;

    if (rowData !== newRowData) {
      try {
        const newData = newRowData as LearningObjective;
        await updateLearningObjectiveMutation.mutateAsync({
          id: newData.id,
          summary: newData.summary,
          description: newData.description,
        });
        utils.lesson.get.invalidate();
      } catch (err) {
        console.error("error updating prompt", err);
      }
    }
  };

  const textEditor = (options: any) => {
    return (
      <InputTextarea
        autoResize
        cols={80}
        style={{ width: "100%" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  function viewObjectiveTemplate(objective: LearningObjective) {
    return (
      <Button
        link
        label="view"
        onClick={() => navigate(`objective/${objective.id}`)}
      ></Button>
    );
  }

  return (
    <>
      {lesson && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
          }}
        >
          <div style={{ paddingRight: "10px" }}>Course:</div>
          <SelectLessonCourse
            id={lesson.id}
            contentType="lesson"
            currentCourseId={lesson.courseId ?? undefined}
          />
        </div>
      )}

      <TabView
        activeIndex={activeTabIndex}
        onTabChange={(e) => setActiveTabIndex(e.index)}
      >
        <TabPanel header="Content">
          <Fieldset legend="Lesson View" toggleable className="m-4">
            <div className="card flex justify-content-center">
              <SelectButton
                value={lessonTypeSelection}
                onChange={(e) => {
                  setLessonTypeSelection(e.value);
                }}
                options={lessonTypeOptions}
              />
            </div>
            {lessonTypeSelection === lessonTypeOptions[0] && (
              <div className="flex flex-column gap-2">
                <label htmlFor="videoUrl">Video URL</label>
                <div className="flex flex-row gap-2">
                  <InputText
                    className="flex-1"
                    value={videoUrl ?? undefined}
                    id="videoUrl"
                    onChange={(e) => setVideoUrl(e.target.value)}
                    aria-describedby="videoUrl-help"
                  />
                  <VideoSelect onVideoSelected={(v) => setVideoUrl(v.hlsUrl)} />
                </div>
                <small id="videoUrl-help">
                  Enter a m3u8 video url or select a video.
                </small>

                <label htmlFor="posterUrl">Poster URL</label>
                <InputText
                  value={posterUrl ?? undefined}
                  id="posterUrl"
                  onChange={(e) => setPosterUrl(e.target.value)}
                  aria-describedby="posterUrl-help"
                />
                <small id="videoUrl-help">Enter an image url.</small>
              </div>
            )}
            {lessonTypeSelection === lessonTypeOptions[1] && (
              <div className="flex flex-column gap-2">
                <label htmlFor="lessonPageUrl">Lesson Page URL</label>
                <InputText
                  value={lessonPageUrl ?? undefined}
                  id="lessonPageUrl"
                  onChange={(e) => setLessonPageUrl(e.target.value)}
                />
              </div>
            )}
            <Button
              label="save"
              style={{ width: "100px", marginTop: "20px" }}
              onClick={() => updateLesson()}
            />
            <div
              style={{
                display: videoUrl ? "block" : "none",
                width: "400px",
                marginTop: "1em",
              }}
            >
              <VideoPlayer
                options={{
                  autoplay: false,
                  controls: true,
                  fluid: true,
                  loop: false,
                  preload: "auto",
                }}
                onReady={(player: Player) => {
                  playerRef.current = player;
                  setIsPlayerReady(true);
                }}
              />
            </div>
          </Fieldset>
          <Fieldset
            legend="Text Content for Language Model"
            toggleable
            collapsed={true}
          >
            <PlainTextEditor
              key={lesson?.id}
              initialText={content ?? ""}
              onTextChange={(update) => setContent(update)}
            ></PlainTextEditor>
            <Button
              label="save"
              style={{ width: "100px" }}
              onClick={() => updateLesson()}
            />
          </Fieldset>
        </TabPanel>
        <TabPanel header="Learning Objectives & Question Prompts">
          <Card title="Learning Objectives">
            {lesson?.learningObjectives?.length &&
            lesson?.learningObjectives?.length > 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "1rem",
                    padding: "0 1rem",
                  }}
                >
                  <Button
                    className={styles.wipeButton}
                    onClick={() => {
                      setConfirmWipeVisible(true);
                    }}
                  >
                    Wipe all
                  </Button>

                  <span style={{ marginRight: "10px" }}>edit</span>
                  <InputSwitch
                    checked={isObjectivesEditMode}
                    onChange={() =>
                      setIsObjectivesEditMode(!isObjectivesEditMode)
                    }
                  />
                </div>
                <DataTable
                  editMode="cell"
                  value={lesson.learningObjectives}
                  tableStyle={{ minWidth: "50rem" }}
                  rowClassName={() => ({
                    [styles.clickableRow]: true,
                  })}
                  selectionMode={null}
                  selection={selectedObjectives}
                  onSelectionChange={(e: any) => setSelectedObjectives(e.value)}
                >
                  <Column
                    selectionMode={
                      isObjectivesEditMode ? "multiple" : undefined
                    }
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                    field="summary"
                    header="Summary"
                  ></Column>
                  <Column
                    editor={(options) => textEditor(options)}
                    onCellEditComplete={onCellEditComplete}
                    field="description"
                    header="Description"
                  ></Column>
                  <Column field="_count.prompts" header="Prompts"></Column>
                  <Column body={viewObjectiveTemplate}></Column>
                </DataTable>
                <div className="pt-4">
                  <Button
                    icon="pi pi-external-link"
                    onClick={() => setShowAddObjectiveDialog(true)}
                    label="Add Objective"
                  ></Button>
                  {selectedObjectives?.length > 0 && (
                    <Button
                      severity="warning"
                      onClick={() => setShowDeleteObjectivesDialog(true)}
                      label={`Delete ${
                        selectedObjectives.length
                      } Selected Objective${
                        selectedObjectives.length > 1 ? "s" : ""
                      }`}
                      className="m-10"
                    ></Button>
                  )}
                  <ConfirmWipeDialog
                    lessonName={lesson?.title}
                    visible={confirmWipeVisible}
                    onHide={() => setConfirmWipeVisible(false)}
                    onAccept={deleteAllObjectives}
                  />
                </div>
              </div>
            ) : lesson?.content ? (
              <Button
                text
                label={generatingObjectives ? "Generating" : "Generate"}
                onClick={generateObjectives}
                loading={generatingObjectives}
              />
            ) : (
              <div>
                <p>
                  Please add lesson content before generating learning
                  objectives.
                </p>
              </div>
            )}
          </Card>
          <Dialog
            visible={showDeleteObjectivesDialog}
            onHide={() => setShowDeleteObjectivesDialog(false)}
            header={`Delete Objective${
              selectedObjectives.length > 1 ? "s" : ""
            }`}
            footer={deleteObjectiveFooter}
            style={{ width: "50vw", height: "50vh" }}
          >
            {deletionStatus ? (
              <div>{deletionStatus}</div>
            ) : (
              <div>
                Deleting this Learning Objective will delete all associated
                data:
                <ul>
                  <li>Any associated Rubric</li>
                  <li>All Prompts associated with the objective</li>
                </ul>
              </div>
            )}
          </Dialog>
          <Dialog
            visible={showAddObjectiveDialog}
            onHide={() => setShowAddObjectiveDialog(false)}
            header="Add Objective"
            footer={addObjectiveFooter}
            style={{ width: "75vw", height: "75vh" }}
          >
            {!creatingObjective ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <InputText
                  onChange={(e) =>
                    setNewObjective((objectiveState) => ({
                      ...objectiveState,
                      summary: e.target.value,
                    }))
                  }
                  value={newObjective.summary}
                  style={{ marginBottom: "1rem" }}
                />
                <PlainTextEditor
                  initialText={newObjective.description}
                  onTextChange={(text) =>
                    setNewObjective((objectiveState) => ({
                      ...objectiveState,
                      description: text,
                    }))
                  }
                  isEditingState={true}
                  style={{ flex: 1 }}
                ></PlainTextEditor>
              </div>
            ) : (
              <ProgressSpinner />
            )}
          </Dialog>
        </TabPanel>
      </TabView>
    </>
  );
}

export default LessonDetail;
