import { trpc } from "@arena-active/trpc-client";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { AddEntityProps } from "./AddEntityProps";

export const AddCourse: React.FC<AddEntityProps> = ({ onComplete }) => {
  const addCourseMutation = trpc.course.upsert.useMutation();
  const [courseTitle, setCourseTitle] = useState("");
  const [createdCourseId, setCreatedCourseId] = useState<number | undefined>();

  useEffect(() => {
    if (addCourseMutation.isSuccess) {
      onComplete(true, createdCourseId);
    }
  }, [addCourseMutation.isSuccess, createdCourseId, onComplete]);

  async function addCourse() {
    const { data } = await addCourseMutation.mutateAsync({
      title: courseTitle,
    });
    data.id && setCreatedCourseId(data.id);
  }

  return (
    <>
      {!addCourseMutation.isLoading ? (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <InputText
            value={courseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
            style={{ marginBottom: "1rem" }}
            placeholder="Course Title"
          />

          <div>
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => onComplete(false)}
                className="p-button-text"
              />
              <Button
                label="Add"
                icon="pi pi-check"
                onClick={addCourse}
                autoFocus
              />
            </div>
          </div>
        </div>
      ) : (
        <ProgressSpinner />
      )}
    </>
  );
};
