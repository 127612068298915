import React from "react";
import styles from "./MessageItem.module.css";

export interface MessageItemProps {
  message: string;
  isUser: boolean;
}

const Icon = (props: { isUser: boolean }) => (
  <i
    className={`pi ${props.isUser ? "pi-user" : "pi-android"}`}
    style={{ fontSize: "1.5rem", marginRight: "10px" }}
  />
);

const Typing = () => (
  <div className={styles.typing}>
    <div className={styles.dots}>
      <div style={{ opacity: 0.0 }}>I</div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
    </div>
  </div>
);

type ItemProps = React.PropsWithChildren<{ isUser: boolean }>;

const Item: React.FC<ItemProps> = ({ isUser, children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center", // Add this to align items vertically
      marginBottom: "10px",
    }}
  >
    <Icon isUser={isUser} />
    <div
      className="p-component"
      style={{
        flex: 1,
        paddingLeft: "10px",
        paddingRight: "10px",
        background: isUser ? "#f0f0ff" : "#f0f0f0",
        padding: "15px",
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  </div>
);

export const MessageItem: React.FC<MessageItemProps> = ({
  message,
  isUser,
}) => {
  return <Item isUser={isUser}>{message}</Item>;
};

export type MessageArray = (PendingMessage | ReadableMessage)[];

export type MessageType = "HUMAN" | "ASSISTANT" | "SYSTEM";

export type PendingMessage = {
  uid: string;
  timestamp: number;
  type: MessageType;
  placeholder: "...";
};

export type ReadableMessage = {
  uid: string;
  content: string;
  timestamp: number;
  type: MessageType;
};

export function isPendingMessage(
  m: PendingMessage | ReadableMessage,
): m is PendingMessage {
  return (m as PendingMessage).placeholder !== undefined;
}

type PendingMessageItemProps = PendingMessage;

export const PendingMessageItem: React.FC<PendingMessageItemProps> = ({
  type: source,
}) => (
  <Item isUser={source === "HUMAN"}>
    <Typing />
  </Item>
);
