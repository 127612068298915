import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { useState } from "react";
import styles from "./LessonList.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { trpc, Course, CourseWithCounts } from "@arena-active/trpc-client";
import { AddCourse } from "./add-entity/AddCourse";

function CourseList() {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState<CourseWithCounts[]>(
    [],
  );

  const [showAddCourseDialog, setShowAddCourseDialog] = useState(false);

  const [deletionStatus, setDeletionStatus] = useState<string | null>(null);
  const [showDeleteCoursesDialog, setShowDeleteCoursesDialog] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  const courseListQuery = trpc.course.list.useQuery({ includeCounts: true });
  const deleteManyMutation = trpc.course.deleteMany.useMutation();

  if (!courseListQuery.data) return <div>Loading...</div>;
  if (courseListQuery.error)
    return <div>Error: {courseListQuery.error.message}</div>;

  function countTemplate(
    rowData: CourseWithCounts,
    fieldName: "lessons" | "activeLessons" | "enrollees",
  ) {
    return rowData._count?.[fieldName] ?? 0;
  }

  function gotoCourse(id: string) {
    navigate(`/course/${id}`);
  }

  function viewCourseTemplate(course: Course) {
    return (
      <Button
        link
        label="view"
        onClick={() => navigate(`/course/${course.id}`)}
      ></Button>
    );
  }

  async function deleteSelectedCourses() {
    try {
      setDeletionStatus(
        `deleting ${selectedCourses.length} lesson${
          selectedCourses.length > 1 ? "s" : ""
        }`,
      );

      await deleteManyMutation.mutateAsync(
        selectedCourses.map((course) => course.id),
      );
      utils.course.list.invalidate();
      setSelectedCourses([]);
      setShowDeleteCoursesDialog(false);
      setDeletionStatus(null);
    } catch (error) {
      console.error("Error: courses were not deleted:", error);
      setDeletionStatus(`Error deleting courses`);
    }
  }

  const deleteCourseFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setShowDeleteCoursesDialog(false)}
        className="p-button-text"
      />
      <Button
        label="Delete"
        icon="pi pi-check"
        onClick={() => deleteSelectedCourses()}
        autoFocus
      />
    </div>
  );

  function handleAddCourseComplete(added: boolean): void {
    // TODO - add toast notfications
    setShowAddCourseDialog(false);
    if (added) {
      console.log("Course added successfully");
      utils.course.list.invalidate();
    } else {
      console.error("cancelled adding course");
    }
  }

  return (
    <div>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "1rem",
            padding: "0 1rem",
          }}
        >
          <span style={{ marginRight: "10px" }}>edit</span>
          <InputSwitch
            checked={isEditMode}
            onChange={() => setIsEditMode(!isEditMode)}
          />
        </div>

        <DataTable
          dataKey="id"
          value={courseListQuery.data}
          tableStyle={{ minWidth: "50rem" }}
          rowClassName={() => ({
            [styles.clickableRow]: true,
          })}
          onRowClick={isEditMode ? undefined : (e) => gotoCourse(e.data.id)}
          onRowDoubleClick={(e) => gotoCourse(e.data.id)}
          selectionMode={null}
          selection={selectedCourses}
          onSelectionChange={(e) => setSelectedCourses(e.value)}
        >
          <Column
            selectionMode={isEditMode ? "multiple" : undefined}
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column field="title" header="Title"></Column>
          <Column
            field="activeLessons"
            header="# Active Lessons"
            body={(rowdata) => countTemplate(rowdata, "activeLessons")}
          ></Column>
          <Column
            field="lessons"
            header="# Lesson Parts"
            body={(rowdata) => countTemplate(rowdata, "lessons")}
          ></Column>
          <Column
            field="enrollees"
            header="# Enrollees"
            body={(rowdata) => countTemplate(rowdata, "enrollees")}
          ></Column>
          <Column body={viewCourseTemplate}></Column>
        </DataTable>
        <div className="pt-4">
          <Button
            icon="pi pi-external-link"
            onClick={() => setShowAddCourseDialog(true)}
            label="Add Course"
          ></Button>
        </div>
        <div className="pt-4">
          {isEditMode && selectedCourses?.length > 0 && (
            <Button
              severity="warning"
              onClick={() => setShowDeleteCoursesDialog(true)}
              label={`Delete ${selectedCourses.length} Selected Course${
                selectedCourses.length > 1 ? "s" : ""
              }`}
              className="m-10"
            ></Button>
          )}
        </div>
      </Card>
      <Dialog
        visible={showAddCourseDialog}
        header="Add Course"
        onHide={() => setShowAddCourseDialog(false)}
        style={{ width: "50vw" }}
      >
        <AddCourse onComplete={handleAddCourseComplete} />
      </Dialog>
      <Dialog
        visible={showDeleteCoursesDialog}
        onHide={() => setShowDeleteCoursesDialog(false)}
        header={`Delete Course${selectedCourses.length > 1 ? "s" : ""}`}
        footer={deleteCourseFooter}
        style={{ width: "50vw", height: "50vh" }}
      >
        {deletionStatus ? (
          <div>{deletionStatus}</div>
        ) : (
          <div>
            Deleting this course will delete all associated data:
            <ul>
              <li>Lesson Content</li>
              <li>ActiveLessons</li>
            </ul>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default CourseList;
