import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { useEffect, useState } from "react";
import LessonList from "./LessonList";
import ActiveLessonList from "./ActiveLessonList";

function Home() {
  const getInitialTabIndex = () => {
    const savedIndex = localStorage.getItem("activeTabIndex");
    if (savedIndex !== null) {
      return parseInt(savedIndex);
    }
    return 0; // Default to 0 if there is no saved index
  };

  const [activeTabIndex, setActiveTabIndex] = useState(getInitialTabIndex);

  useEffect(() => {
    localStorage.setItem("activeTabIndex", activeTabIndex.toString());
  }, [activeTabIndex]);

  return (
    <TabView
      activeIndex={activeTabIndex}
      onTabChange={(e) => setActiveTabIndex(e.index)}
    >
      <TabPanel header="Lessons">
        <LessonList />
      </TabPanel>
      <TabPanel header="Active Lessons">
        <ActiveLessonList />
      </TabPanel>
    </TabView>
  );
}

export default Home;
