import { required } from "@arena-active/client-lib";

export const API_URL =
  required("API_URL", import.meta.env.VITE_API_HOST) + "/trpc";
export const VIDEO_CLOUDFRONT_URL = required(
  "VIDEO_CLOUDFRONT_URL",
  import.meta.env.VITE_VIDEO_CLOUDFRONT_URL,
);
export const VIDEO_BUCKET = required(
  "VIDEO_BUCKET",
  import.meta.env.VITE_VIDEO_BUCKET,
);
