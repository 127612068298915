import React, { useState } from "react";
import ChatInput from "./ChatInput";
import { MessageList } from "./MessageList";
import { isPendingMessage, MessageArray } from "./MessageItem";
import { createGUID } from "../../util";

interface ChatProps {
  messageHandler: (userInput: string) => Promise<MessageArray>;
  readonly?: boolean;
}

const defaultProps = {
  readonly: false,
} satisfies Partial<ChatProps>;

export const Chat: React.FC<ChatProps> = (props) => {
  const { messageHandler, readonly } = { ...defaultProps, ...props };
  const [messages, setMessages] = useState<MessageArray>([]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <MessageList messages={messages} />
      <ChatInput
        onSend={(userInput) => {
          setMessages((p) =>
            p.concat([
              {
                uid: createGUID(),
                type: "HUMAN",
                timestamp: Date.now(),
                content: userInput,
              },
              {
                uid: createGUID(),
                type: "ASSISTANT",
                timestamp: Date.now(),
                placeholder: "...",
              },
            ]),
          );

          messageHandler(userInput).then((messages) =>
            setMessages((p) =>
              p.concat(messages).filter((m) => !isPendingMessage(m)),
            ),
          );
        }}
        disabled={readonly}
      />
    </div>
  );
};

export default Chat;
