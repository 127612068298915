import { EncodedVideoUrls } from "@arena-active/encoding-client";
import { VideoUploadService } from "./VideoUploadService";

export class VideoUploaderJob extends EventTarget {
  public id: number;
  public name: string;
  public file: File;
  public state: string = "pending";
  public downloadUrl: string = "";
  public uploadProgress: number = 0;
  public encodingState: string = "pending";
  public encodedVideoUrls: EncodedVideoUrls | undefined;
  public error: string = "";
  public createdAt: Date = new Date();
  public service: VideoUploadService;

  constructor(
    id: number,
    name: string,
    file: File,
    service: VideoUploadService,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.file = file;
    this.service = service;
  }

  public emitStateChange(state: string) {
    this.state = state;
    this.dispatchEvent(new Event("stateChange"));
  }

  public async run() {
    await this.upload();
    await this.encode();
    this.emitStateChange("complete");
    await this.service.updateVideoUpload(this.id, this.state);
    const videoUpload = await this.service.getVideoUpload(this.id);
    console.log("Video upload complete", videoUpload);
  }

  public async upload() {
    this.emitStateChange("uploading");
    await this.service.updateVideoUpload(this.id, this.state);

    const response = await this.service.uploadFile(
      this.file,
      this.name,
      (progress: number) => {
        console.log("Progress", progress);
        this.uploadProgress = Math.round(progress);
        this.emitStateChange(this.state);
      },
    );
    if (response.success) {
      this.downloadUrl = response.downloadUrl;
      this.emitStateChange("uploaded");
      await this.service.updateVideoUpload(this.id, this.state, {
        sourceUrl: this.downloadUrl,
      });
    } else {
      this.error = response.message;
      this.emitStateChange("error");
      await this.service.updateVideoUpload(this.id, this.state);
    }
  }

  public async encode() {
    this.emitStateChange("encoding");
    await this.service.updateVideoUpload(this.id, this.state);
    const response = await this.service.encodeFile(
      this.downloadUrl,
      (state: string) => {
        this.encodingState = state;
        this.emitStateChange(this.state);
      },
    );
    if (response.success) {
      this.encodedVideoUrls = response.urls;
      this.emitStateChange("encoded");
      await this.service.updateVideoUpload(this.id, this.state, {
        duration: response.duration,
        audioUrl: response.urls.audio,
        baseUrl: response.urls.base,
        dashUrl: response.urls.dash,
        hlsUrl: response.urls.hls,
        spriteUrl: response.urls.sprite,
        thumbnailUrl: response.urls.thumbnail,
        vttUrl: response.urls.vtt,
      });
    } else {
      this.error = response.message;
      this.emitStateChange("error");
      await this.service.updateVideoUpload(this.id, this.state);
    }
  }

  public isPending() {
    return this.state === "pending";
  }

  public isDone() {
    return this.state === "complete" || this.state === "error";
  }

  public isBusy() {
    return !(
      this.state === "pending" ||
      this.state === "complete" ||
      this.state === "error"
    );
  }

  public getProps() {
    return {
      name: this.name,
      state: this.state,
      downloadUrl: this.downloadUrl,
    };
  }
}
