import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MouseEvent, useRef, useState } from "react";
import { AddCourse } from "./add-entity/AddCourse";
import { useNavigate } from "react-router-dom";
import { AddLessonPart } from "./add-entity/AddLessonPart";
import { useVideoUploader } from "./useVideoUploader";

export const HeaderCreateMenu = () => {
  const navigate = useNavigate();
  const [showAddCourseDialog, setShowAddCourseDialog] = useState(false);
  const [showAddLessonDialog, setShowAddLessonDialog] = useState(false);
  const { setUploadDialogVisible } = useVideoUploader();

  const createMenu = useRef(null);
  const items = [
    {
      label: "Create",
      items: [
        {
          label: "Course...",
          command: () => setShowAddCourseDialog(true),
        },
        {
          label: "Lesson Part...",
          command: () => setShowAddLessonDialog(true),
        },
        {
          label: "Active Lesson...",
        },
        {
          label: "Video...",
          command: () => setUploadDialogVisible(true),
        },
      ],
    },
  ];

  function handleAddCourseComplete(added: boolean, courseId?: number): void {
    setShowAddCourseDialog(false);
    if (added) {
      navigate(`/course/${courseId}`);
    } else {
      console.error("cancelled adding course");
    }
  }

  function handleAddLessonComplete(added: boolean, lessonId?: number): void {
    setShowAddLessonDialog(false);
    if (added) {
      navigate(`/lesson/${lessonId}`);
    } else {
      console.error("cancelled adding lesson");
    }
  }

  const handleClick = (event: MouseEvent) => {
    if (createMenu.current) {
      (createMenu.current as Menu).toggle(event);
    }
  };
  return (
    <div>
      <Menu model={items} popup ref={createMenu} id="popup_menu_create" />
      <Button
        outlined
        label="Create"
        aria-label="Create"
        icon="pi pi-plus"
        iconPos="left"
        onClick={handleClick}
        aria-controls="popup_menu_create"
        aria-haspopup
      />
      <Dialog
        visible={showAddCourseDialog}
        header="Add Course"
        onHide={() => setShowAddCourseDialog(false)}
        style={{ width: "50vw" }}
      >
        <AddCourse onComplete={handleAddCourseComplete} />
      </Dialog>
      <Dialog
        visible={showAddLessonDialog}
        header="Add Lesson Part"
        onHide={() => setShowAddLessonDialog(false)}
        style={{ width: "50vw" }}
      >
        <AddLessonPart onComplete={handleAddLessonComplete} />
      </Dialog>
    </div>
  );
};
