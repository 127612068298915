import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import styles from "./ConfirmWipeDialog.module.css";
interface ConfirmWipeDialogProps {
  visible: boolean;
  lessonName: string;
  onHide: () => void;
  onAccept: () => void;
}

export const ConfirmWipeDialog = ({
  visible,
  lessonName,
  onHide,
  onAccept,
}: ConfirmWipeDialogProps) => {
  const [enteredName, setEnteredName] = useState<string>("");
  return (
    <Dialog header="Confirm Wipe" visible={visible} onHide={onHide}>
      <div>
        Are you sure you want to wipe all Objectives, Rubric and Prompts?
      </div>

      <div>
        Please enter the name of the lesson below to confirm: {lessonName}
      </div>

      <div>
        <InputText
          className={styles.input}
          value={enteredName}
          onChange={(e) => setEnteredName(e.target.value)}
        />
      </div>

      <div className={styles.buttons}>
        <Button
          label="Yes"
          disabled={enteredName !== lessonName}
          onClick={onAccept}
        />
        <Button label="Cancel" severity="secondary" onClick={onHide} />
      </div>
    </Dialog>
  );
};
